import styles from './ECommerceDashboard.module.css'
import moment from 'moment'
import { Space, DatePicker, Row, Col, Spin, Skeleton, Dropdown, Menu, Tooltip, Popover, Checkbox, Button } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { fetchECommerceDeliveryPartnerAnalyticsOrderRate, fetchGeneralAnalyticsCancelledOrderReason, fetchGeneralAnalyticsDiscountAndAdvancePaymentOrderStats, fetchGeneralAnalyticsOrderCycleTime, fetchGeneralAnalyticsOrderRate, fetchGeneralAnalyticsOrderSource, fetchGeneralAnalyticsReturnedOrderReason, fetchGeneralAnalyticsStatistics, fetchSalesAnalyticsAverageBasket, fetchSalesAnalyticsOrders, fetchSalesAnalyticsPaymentStatistics, fetchTopCustomers, fetchTopSellingProductsInTimeRange } from '../../../store/dashboard/actions'
import { ReloadOutlined, RightOutlined, LeftOutlined, DownOutlined, UpOutlined } from '@ant-design/icons'
import { convertEnumToString, getCurrencyCode, getFormattedCurrency } from '../../../utils'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import Table from '../../../components/table'
import Link from 'next/link'
import { LocationTypes, PartnerType, orderSourceColors, orderStatuses, paths, returnOrCancelReasons, timeSpan } from '../../../utils/constants'
import { Products } from '../../../services/api/products'
import useSearchParams from '../../../hooks/useSearchParams'
import CustomEmptySecondary from '../../custom-empty-secondary'
import CustomerTag from '../../customer-tag'
import TopSalesOrderLocations from '../../top-sales-order-locations'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { Locations } from '../../../services/api/locations'
import { Distributors } from '../../../services/api/distributors'
import { Dashboard } from '../../../services/api/dashboard'

const dateFormatStringWithTime = 'MMM D, YYYY h:mm A'
const dateFormatString = 'MMM D, YYYY'

const timeDifferentiateTabs = [
	{
		title: 'Daily',
		value: 'daily'
	},
	{
		title: 'Weekly',
		value: 'weekly'
	},
	{
		title: 'Monthly',
		value: 'monthly'
	},
	{
		title: 'Yearly',
		value: 'annually'
	}
]

export const timePeriods = [
	{
		label: 'Today',
		key: timeSpan.today
	},
	{
		label: 'Yesterday',
		key: timeSpan.yesterday
	},
	{
		label: 'Last 7 days',
		key: timeSpan.week
	},
	{
		label: 'Last 30 days',
		key: timeSpan.month
	},
	{
		label: 'This Week',
		key: timeSpan.currentWeek
	},
	{
		label: 'This Month',
		key: timeSpan.currentMonth
	},
	{
		label: 'This Year',
		key: timeSpan.year
	},
	{
		label: 'Lifetime',
		key: timeSpan.lifetime
	},
	{
		label: 'Custom',
		key: timeSpan.custom
	}
]

const customerFilters = [
	{
		label: 'Order Frequency',
		key: 'frequency'
	},
	{
		label: 'Order Value',
		key: 'value'
	}
]

const transitionStatuses = [
	{
		label: 'By Requested',
		key: 'requested'
	},
	{
		label: 'By Approved',
		key: 'approved'
	},
	{
		label: 'By In-Transit',
		key: 'in_transit'
	},
	{
		label: 'By Delivered',
		key: 'completed'
	}
]

const productTypes = [
	{
		label: 'By Variants',
		key: 'variants'
	},
	{
		label: 'By Products',
		key: 'products'
	}
]

const getOrderRatePieChartData = (orderRateData) => {
	return [
		{
			'id': 'Pending',
			'label': 'Pending',
			'value': orderRateData.requested || 0,
			'color': '#1F4286'
		},
		{
			'id': 'On Hold',
			'label': 'On Hold',
			'value': orderRateData.on_hold || 0,
			'color': '#FFCDCD'
		},
		{
			'id': 'Approved',
			'label': 'Approved',
			'value': orderRateData.approved || 0,
			'color': '#49BEB7'
		},
		{
			'id': 'Processing',
			'label': 'Processing',
			'value': orderRateData.processing || 0,
			'color': '#288EA5'
		},
		{
			'id': 'Shipped',
			'label': 'Shipped',
			'value': orderRateData.shipped || 0,
			'color': '#5780EA'
		},
		{
			'id': 'In-Transit',
			'label': 'In-Transit',
			'value': orderRateData.in_transit || 0,
			'color': '#7A5927'
		},
		{
			'id': 'Delivered',
			'label': 'Delivered',
			'value': orderRateData.completed || 0,
			'color': '#085F63'
		},
		{
			'id': 'Cancelled',
			'label': 'Cancelled',
			'value': orderRateData.cancelled || 0,
			'color': '#FF0000'
		},
		{
			'id': 'Flagged',
			'label': 'Flagged',
			'value': orderRateData.flagged || 0,
			'color': '#FF5959'
		}
	]
}

const cancelledOrderReasonColors = {
	[returnOrCancelReasons.HIGH_PRICE]: '#802D2D',
	[returnOrCancelReasons.SHORT_TIME_DELIVERY]: '#B33E3E',
	[returnOrCancelReasons.OUT_OF_ZONE]: '#E65050',
	[returnOrCancelReasons.DUPLICATE_ORDER]: '#FF7A7A',
	[returnOrCancelReasons.FAKE_ORDER]: '#fc3f3f',
	[returnOrCancelReasons.CHANGED_MIND]: '#7a1010',
	[returnOrCancelReasons.OTHER_REASON]: '#FF9C9C'
}

const returnedOrderReasonColors = {
	[returnOrCancelReasons.DAMAGED_PRODUCT]: '#802D2D',
	[returnOrCancelReasons.DELAY_DELIVERY]: '#B33E3E',
	[returnOrCancelReasons.WRONG_PRODUCT]: '#E65050',
	[returnOrCancelReasons.OUT_OF_ZONE]: '#FF6A6A',
	[returnOrCancelReasons.FRAUD_CUSTOMER]: '#FF8B8B',
	[returnOrCancelReasons.DELIVERY_MAN_CARELESSNESS]: '#FF9C9C',
	[returnOrCancelReasons.OTHER_REASON]: '#FFBDBD'
}

const deliveryPartnerRenderInfo = {
	[PartnerType.PATHAO]: {
		label: 'Pathao',
		color: '#D64E3F'
	},
	[PartnerType.E_COURIER]: {
		label: 'E-Courier',
		color: '#5A3288'
	},
	[PartnerType.PAPERFLY]: {
		label: 'Paperfly',
		color: '#4EACE8'
	},
	[PartnerType.PIDEX]: {
		label: 'Pidex',
		color: '#E1733A'
	},
	[PartnerType.REDX]: {
		label: 'RedX',
		color: '#D93A34'
	},
	[PartnerType.STEADFAST]: {
		label: 'Steadfast',
		color: '#58A58A'
	},
	[PartnerType.MOVEX]: {
		label: 'MoveX',
		color: '#D93A34'
	},
	[PartnerType.TCS_COURIER]: {
		label: 'TCS Courier',
		color: '#D93A34'
	},
	[PartnerType.RIDER_COURIER]: {
		label: 'Rider Courier',
		color: '#4EACE8'
	},
	[PartnerType.POST_EX]: {
		label: 'PostEx',
		color: '#000000'
	},
	[PartnerType.MP_COURIER]: {
		label: 'M & P Courier',
		color: '#E25A0C'
	},
	[PartnerType.LEOPARDS]: {
		label: 'Leopards',
		color: '#F0BE00'
	},
	[PartnerType.BLUE_EX]: {
		label: 'BlueEx',
		color: '#0047BA'
	},
	[PartnerType.PAKISTAN_POST]: {
		label: 'Pakistan Post',
		color: '#FFBF00'
	},
	[PartnerType.CUSTOM]: {
		label: 'Custom',
		color: '#288EA5'
	},
	[PartnerType.PANDAGO]: {
		label: 'Pandago',
		color: '#d70f64'
	},
	[PartnerType.XPRESS_IN_TOWN]: {
		label: 'Xpress In Town',
		color: '#e8bc46'
	}
}

const statBystatusColor = {
	APPROVED: {
		'label': 'Approved',
		'color': '#1CB89B'
	},
	PROCESSING: {
		'label': 'Processing',
		'color': '#8075F9'
	},
	CANCELLED: {
		'label': 'Cancelled',
		'color': '#CC4747'
	},
	REQUESTED: {
		'label': 'Pending',
		'color': '#F7B44F'
	},
	PAYMENT_COLLECTED: {
		'label': 'Delivered Payment Collected',
		'color': '#288EA5'
	},
	PAYMENT_DUE: {
		'label': 'Delivered Payment Due',
		'color': '#F7B44F'
	},
	SHIPPED: {
		'label': 'Shipped',
		'color': '#5780EA'
	},
	IN_TRANSIT: {
		'label': 'In-Transit',
		'color': '#7A5927'
	},
	RETURNED: {
		'label': 'Returned',
		'color': '#FF6A6A'
	},
	DAMAGED: {
		'label': 'Damaged',
		'color': '#FF6A6A'
	},
	FLAGGED: {
		'label': 'Returned',
		'color': '#FF6A6A'
	},
	ON_HOLD: {
		'label': 'On Hold',
		'color': '#FFCDCD'
	}
}

const getOrderCycleChartData = (orderCycleChart) => {
	return [
		{
			'id': 'In-Transit',
			'value': orderCycleChart.IN_TRANSIT || 0,
			'color': statBystatusColor.IN_TRANSIT.color
		},
		{
			'id': 'Shipped',
			'value': orderCycleChart.SHIPPED || 0,
			'color': statBystatusColor.SHIPPED.color
		},
		{
			'id': 'Processing',
			'value': orderCycleChart.PROCESSING || 0,
			'color': statBystatusColor.PROCESSING.color
		},
		{
			'id': 'Approved',
			'value': orderCycleChart.APPROVED || 0,
			'color': statBystatusColor.APPROVED.color
		},
		{
			'id': 'On Hold',
			'value': orderCycleChart.ON_HOLD || 0,
			'color': statBystatusColor.ON_HOLD.color
		},
		{
			'id': 'Pending',
			'value': orderCycleChart.REQUESTED || 0,
			'color': statBystatusColor.REQUESTED.color
		}
	]
}

const discountAndAdvancedPayments = {
	'DISCOUNT_ALLOWED': {
		label: 'Discount Allowed',
		color: '#0F3E49'
	},
	'ADVANCED_PAYMENT': {
		label: 'Advanced Payment',
		color: '#288EA5'
	}
}

const customerColumns = [
	{
		title: 'Customer ID',
		key: 'customerId',
		render: customer => {
			return (
				<Link href={`/customers/${customer.id}`}>
					<a className={styles.link}>{customer.internalId ? customer.internalId : `C-${customer.shortId}`}</a>
				</Link>
			)
		}
	},
	{
		title: 'Phone',
		key: 'phone',
		render: customer => {
			return customer.phone
		}
	},
	{
		title: 'Name',
		key: 'name',
		render: customer => {
			return (
				<div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
					{customer.name}
					<CustomerTag name={customer?.customerTag?.name} />
				</div>
			)
		}
	},
	{
		title: 'Address',
		key: 'address',
		render: customer => {
			return customer.locations[0]?.address
		}
	},
	{
		title: 'District',
		key: 'district',
		render: customer => {
			return customer.locations[0]?.district
		}
	},
	{
		title: 'Order Frequency',
		key: 'orderFrequency',
		render: customer => {
			return customer.orderFrequency
		}
	},
	{
		title: 'Order Value',
		key: 'orderValue',
		render: customer => {
			return getFormattedCurrency(customer.orderValue)
		}
	},
	{
		title: 'Customer Since',
		key: 'customerSince',
		render: customer => {
			return moment(customer.createdAt).format('MMM D, YYYY')
		}
	}
]

const orderStatusOptions = [
	{ label: 'Pending', value: orderStatuses.SALES_ORDER_REQUESTED_STATUS },
	{ label: 'On Hold', value: orderStatuses.SALES_ORDER_ON_HOLD_STATUS },
	{ label: 'Approved', value: orderStatuses.SALES_ORDER_APPROVED_STATUS },
	{ label: 'Processing', value: orderStatuses.SALES_ORDER_PROCESSING_STATUS },
	{ label: 'Shipped', value: orderStatuses.SALES_ORDER_SHIPPED_STATUS },
	{ label: 'In-Transit', value: orderStatuses.SALES_ORDER_IN_TRANSIT_STATUS },
	{ label: 'Delivered Payment Due', value: orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS },
	{ label: 'Delivered Payment Collected', value: orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS },
	{ label: 'Cancelled', value: orderStatuses.SALES_ORDER_CANCELLED_STATUS },
	{ label: 'Returned', value: orderStatuses.SALES_ORDER_RETURNED_STATUS },
	{ label: 'Damaged', value: orderStatuses.SALES_ORDER_DAMAGED_STATUS }
]

const getInitialOrderStatusFilter = () => {
	return orderStatusOptions.reduce((acc, { value }, idx) => ({ ...acc, [value]: idx < 7 }), {})
}

const ECommerceDashboard = () => {
	const dispatch = useDispatch()
	const router = useRouter()
	const { applyFilter } = useSearchParams()
	const {
		generalAnalyticsStatistics,
		generalAnalyticsOrderRate,
		generalAnalyticsOrderCycleTime,
		generalAnalyticsOrderSource,
		generalAnalyticsCancelledOrderReason,
		generalAnalyticsReturnedOrderReason,
		generalAnalyticsDiscountAndAdvancePaymentOrderCount,
		generalAnalyticsDiscountAndAdvancePaymentOrderValue,
		ecommerceDeliveryPartnerAnalyticsOrderRate,
		salesAnalyticsOrders,
		topECommerceCustomers,
		topSellingProductsInTimeRange,
		salesAnalyticsPaymentStatistics,
		salesAnalyticsAverageBasket
	} = useSelector(state => state.dashboardReducer)
	const { permissions, userProfile } = useSelector(state => state.authReducer)
	const [isLoadingSalesAnalyticsPaymentStatistics, setIsLoadingSalesAnalyticsPaymentStatistics] = useState(false)
	const [isLoadingGeneralAnalyticsStatistics, setIsLoadingGeneralAnalyticsStatistics] = useState(false)
	const [isLoadingGeneralAnalyticsOrderRate, setIsLoadingGeneralAnalyticsOrderRate] = useState(false)
	const [isLoadingGeneralAnalyticsOrderCycleTime, setIsLoadingGeneralAnalyticsOrderCycleTime] = useState(false)
	const [isLoadingSalesAnalyticsOrderTrends, setIsLoadingSalesAnalyticsOrderTrends] = useState(false)
	const [isLoadingSalesAnalyticsAverageBasketTrends, setIsLoadingSalesAnalyticsAverageBasketTrends] = useState(false)
	const [customerFilter, setCustomerFilter] = useState(customerFilters[0])
	const [customerStatusFilter, setCustomerStatusFilter] = useState(transitionStatuses[2])
	const [generalOrderCountStatusFilter, setGeneralOrderCountStatusFilter] = useState(transitionStatuses[1])
	const [generalOrderCountCurrentTimeTab, setGeneralOrderCountCurrentTimeTab] = useState(timeDifferentiateTabs[0].value)
	const [generalAverageBasketCurrentTimeTab, setGeneralAverageBasketCurrentTimeTab] = useState(timeDifferentiateTabs[0].value)
	const [averageBasketStatus, setAverageBasketStatus] = useState(transitionStatuses[2])
	const [topProductsStatus, setTopProductsStatus] = useState(transitionStatuses[0])
	const [topProductsType, setTopProductsType] = useState(productTypes[0])
	const [deliveryPartnerOrderStatusFilter, setDeliveryPartnerOrderStatusFilter] = useState(transitionStatuses[2])
	const [isLoadingOrderSource, setIsLoadingOrderSource] = useState(false)
	const [isLoadingCancelledOrderReason, setIsLoadingCancelledOrderReason] = useState(false)
	const [isLoadingDiscountAndAdvancePaymentOrderStats, setIsLoadingDiscountAndAdvancePaymentOrderStats] = useState(false)
	const [isLoadingDeliveryPartnersOrderRate, setIsLoadingDeliveryPartnersOrderRate] = useState(false)
	const [isTopCustomerLoading, setIsTopCustomerLoading] = useState(false)
	const [isLoadingProducts, setIsLoadingProducts] = useState(false)
	const [isDownloadingProducts, setIsDownloadingProducts] = useState(false)
	const [isDownloadingCustomers, setIsDownloadingCustomers] = useState(false)
	const [isDownloadingOrderSources, setIsDownloadingOrderSources] = useState(false)
	const [showGraphView, setShowGraphView] = useState(false)
	const [expanded, setExpanded] = useState(false)
	const [isOrderStatusFilterVisible, setIsOrderStatusFilterVisible] = useState(false)
	const [orderStatusFilter, setOrderStatusFilter] = useLocalStorage(`${'selectedOrderStatuses'}-${userProfile?.id}`, getInitialOrderStatusFilter())
	const [isOrderSourceStatusFilterVisible, setIsOrderSourceStatusFilterVisible] = useState(false)
	const [orderSourceStatusFilter, setOrderSourceStatusFilter] = useLocalStorage(`${'selectedOrderSourceStatuses'}-${userProfile?.id}`, getInitialOrderStatusFilter())
	const [salesOrderLocation, setSalesOrderLocation] = useState({})
	const [showMasterFilterSelected, setShowMasterFilterSelected] = useState(false)
	const [locations, setLocations] = useState([])
	const [selectedLocationIds, setSelectedLocationIds] = useState()

	const [masterFilterDateRange, setMasterFilterDateRange] = useState({
		timePeriod: timePeriods[0],
		fromDate: moment().startOf('day').toDate(),
		toDate: moment().endOf('day').toDate()
	})
	const [statPeriodDateRange, setStatPeriodDateRange] = useState({
		timePeriod: timePeriods[0],
		fromDate: moment().startOf('day').toDate(),
		toDate: moment().endOf('day').toDate()
	})
	const [cancelledOrderReasonDateRange, setCancelledOrderReasonDateRange] = useState({
		timePeriod: timePeriods[0],
		fromDate: moment().startOf('day').toDate(),
		toDate: moment().endOf('day').toDate()
	})
	const [orderCycleDateRange, setOrderCycleDateRange] = useState({
		timePeriod: timePeriods[0],
		fromDate: moment().startOf('day').toDate(),
		toDate: moment().endOf('day').toDate()
	})
	const [orderSourceDateRange, setOrderSourceDateRange] = useState({
		timePeriod: timePeriods[0],
		fromDate: moment().startOf('day').toDate(),
		toDate: moment().endOf('day').toDate()
	})
	const [orderRateDateRange, setOrderRateDateRange] = useState({
		timePeriod: timePeriods[0],
		fromDate: moment().startOf('day').toDate(),
		toDate: moment().endOf('day').toDate()
	})
	const [deliveryPartnersOrderRateDateRange, setDeliveryPartnersOrderRateDateRange] = useState({
		timePeriod: timePeriods[0],
		fromDate: moment().startOf('day').toDate(),
		toDate: moment().endOf('day').toDate()
	})
	const [paymentStatDateRange, setPaymentStatDateRange] = useState({
		timePeriod: timePeriods[0],
		fromDate: moment().startOf('day').toDate(),
		toDate: moment().endOf('day').toDate()
	})
	const [discountAndAdvancePaymentOrderStatsDateRange, setDiscountAndAdvancePaymentOrderStatsDateRange] = useState({
		timePeriod: timePeriods[0],
		fromDate: moment().startOf('day').toDate(),
		toDate: moment().endOf('day').toDate()
	})
	const [topProductsDateRange, setTopProductsDateRange] = useState({
		timePeriod: timePeriods[0],
		fromDate: moment().startOf('day').toDate(),
		toDate: moment().endOf('day').toDate()
	})
	const [customerDateRange, setCustomerDateRange] = useState({
		timePeriod: timePeriods[0],
		fromDate: moment().startOf('day').toDate(),
		toDate: moment().endOf('day').toDate()
	})

	const productsRef = useRef()
	const totalOrderCountRef = useRef(0)

	const analyticsTypes = {
		orders: 'orders',
		sales: 'sales',
		all: 'all'
	}

	useEffect(() => {
		if (userProfile) {
			const { locations } = userProfile
			if (locations && locations.length) {
				setLocations(locations)
			} else {
				getWarehouses()
			}
		}
	}, [userProfile])

	useEffect(() => {
		getSalesAnalyticsAverageBasketTrends()
	}, [generalAverageBasketCurrentTimeTab, averageBasketStatus, selectedLocationIds])

	useEffect(() => {
		getSalesAnalyticsOrderTrends()
	}, [generalOrderCountCurrentTimeTab, generalOrderCountStatusFilter, selectedLocationIds])

	useEffect(() => {
		getGeneralAnalyticsOrderCycleTime()
	}, [orderCycleDateRange.fromDate, orderCycleDateRange.toDate, selectedLocationIds])

	useEffect(() => {
		getGeneralAnalyticsOrderRate()
	}, [orderRateDateRange.fromDate, orderRateDateRange.toDate, selectedLocationIds])

	useEffect(() => {
		getSalesAnalyticsPaymentStatistics()
	}, [paymentStatDateRange.fromDate, paymentStatDateRange.toDate, selectedLocationIds])

	useEffect(() => {
		getGeneralAnalytics()
	}, [statPeriodDateRange.fromDate, statPeriodDateRange.toDate, orderStatusFilter, selectedLocationIds])

	useEffect(() => {
		getTopCustomers()
	}, [customerDateRange.fromDate, customerDateRange.toDate, customerFilter, customerStatusFilter, selectedLocationIds])

	useEffect(() => {
		getTopSellingProducts()
	}, [topProductsDateRange.fromDate, topProductsDateRange.toDate, topProductsStatus, topProductsType, selectedLocationIds])

	useEffect(() => {
		getGeneralAnalyticsOrderSource()
	}, [orderSourceDateRange.fromDate, orderSourceDateRange.toDate, orderSourceStatusFilter, selectedLocationIds])

	useEffect(() => {
		getGeneralAnalyticsCancelledOrderReason()
	}, [cancelledOrderReasonDateRange.fromDate, cancelledOrderReasonDateRange.toDate, selectedLocationIds])

	useEffect(() => {
		getGeneralAnalyticsDiscountAndAdvancePaymentOrderStats()
	}, [discountAndAdvancePaymentOrderStatsDateRange.fromDate, discountAndAdvancePaymentOrderStatsDateRange.toDate, selectedLocationIds])

	useEffect(() => {
		getECommerceDeliveryPartnerAnalyticsOrderRate()
	}, [deliveryPartnersOrderRateDateRange.fromDate, deliveryPartnersOrderRateDateRange.toDate, deliveryPartnerOrderStatusFilter, selectedLocationIds])

	const allChartTimeSchedule = [
		{
			timePeriod: statPeriodDateRange.timePeriod,
			setTimePeriod: setStatPeriodDateRange
		},
		{
			timePeriod: cancelledOrderReasonDateRange.timePeriod,
			setTimePeriod: setCancelledOrderReasonDateRange
		},
		{
			timePeriod: orderCycleDateRange.timePeriod,
			setTimePeriod: setOrderCycleDateRange
		},
		{
			timePeriod: orderSourceDateRange.timePeriod,
			setTimePeriod: setOrderSourceDateRange
		},
		{
			timePeriod: orderRateDateRange.timePeriod,
			setTimePeriod: setOrderRateDateRange
		},
		{
			timePeriod: deliveryPartnersOrderRateDateRange.timePeriod,
			setTimePeriod: setDeliveryPartnersOrderRateDateRange
		},
		{
			timePeriod: paymentStatDateRange.timePeriod,
			setTimePeriod: setPaymentStatDateRange
		},
		{
			timePeriod: discountAndAdvancePaymentOrderStatsDateRange.timePeriod,
			setTimePeriod: setDiscountAndAdvancePaymentOrderStatsDateRange
		},
		{
			timePeriod: topProductsDateRange.timePeriod,
			setTimePeriod: setTopProductsDateRange
		},
		{
			timePeriod: customerDateRange.timePeriod,
			setTimePeriod: setCustomerDateRange
		}
	]

	const setAllTimePeriodsAccordingToMasterFilter = () => {
		if (!masterFilterDateRange.fromDate || !masterFilterDateRange.toDate || !masterFilterDateRange.timePeriod) {
			if (masterFilterDateRange?.timePeriod?.key !== timeSpan?.lifetime) {
				return
			}
		}
		allChartTimeSchedule.map((schedule) => {
			if (masterFilterDateRange?.timePeriod?.key !== timeSpan?.custom) {
				if (masterFilterDateRange?.timePeriod?.key !== schedule?.timePeriod?.key) {
					schedule?.setTimePeriod({
						'timePeriod': masterFilterDateRange?.timePeriod,
						'fromDate': masterFilterDateRange?.fromDate,
						'toDate': masterFilterDateRange?.toDate
					})
				}
			} else {
				schedule?.setTimePeriod({
					'timePeriod': masterFilterDateRange?.timePeriod,
					'fromDate': masterFilterDateRange?.fromDate,
					'toDate': masterFilterDateRange?.toDate
				})
			}
		})
		setSalesOrderLocation({ 'value': masterFilterDateRange?.timePeriod?.key, 'fromDate': moment(masterFilterDateRange?.fromDate), 'toDate': moment(masterFilterDateRange?.toDate) })
	}

	useEffect(() => {
		setAllTimePeriodsAccordingToMasterFilter()
	}, [masterFilterDateRange])

	const getWarehouses = async () => {
		const { data } = await Locations.index({ types: [LocationTypes.WAREHOUSE, LocationTypes.FACTORY], page: -1 })
		setLocations(data.results)
	}

	const dateRangeChangeHandler = (setDateRange, key, value) => {
		setDateRange((previousValue) => ({ ...previousValue, [key] : value }))
	}

	const getTopCustomers = async () => {
		setIsTopCustomerLoading(true)
		const params = getCustomerSearchParams()
		await dispatch(fetchTopCustomers(params))
		setIsTopCustomerLoading(false)
	}

	const getGeneralAnalytics = () => {
		getGeneralAnalyticsStatistics(analyticsTypes.all)
	}

	const getGeneralAnalyticsStatistics = async () => {
		setIsLoadingGeneralAnalyticsStatistics(true)
		const param = getStatSearchParams()
		await dispatch(fetchGeneralAnalyticsStatistics(param.interval, param.fromDate, param.toDate, param.statuses, selectedLocationIds))
		setIsLoadingGeneralAnalyticsStatistics(false)
	}

	const getSalesAnalyticsPaymentStatistics = async () => {
		setIsLoadingSalesAnalyticsPaymentStatistics(true)
		const param = getPaymentStatSearchParams()
		await dispatch(fetchSalesAnalyticsPaymentStatistics(param.interval, param.fromDate, param.toDate, selectedLocationIds))
		setIsLoadingSalesAnalyticsPaymentStatistics(false)
	}

	const getGeneralAnalyticsOrderRate = async (interval) => {
		setIsLoadingGeneralAnalyticsOrderRate(true)
		await dispatch(fetchGeneralAnalyticsOrderRate(interval, orderRateDateRange.fromDate, orderRateDateRange.toDate, selectedLocationIds))
		setIsLoadingGeneralAnalyticsOrderRate(false)
	}

	const getGeneralAnalyticsOrderCycleTime = async () => {
		setIsLoadingGeneralAnalyticsOrderCycleTime(true)
		await dispatch(fetchGeneralAnalyticsOrderCycleTime({
			fromDate: orderCycleDateRange.fromDate,
			toDate: orderCycleDateRange.toDate,
			locationIds: selectedLocationIds
		}))
		setIsLoadingGeneralAnalyticsOrderCycleTime(false)
	}

	const getGeneralAnalyticsOrderSource = async () => {
		setIsLoadingOrderSource(true)
		let statuses = []
		Object.keys(orderSourceStatusFilter).forEach(status => {
			if (orderSourceStatusFilter[status]) {
				statuses.push(status)
			}
		})
		await dispatch(fetchGeneralAnalyticsOrderSource({ fromDate: orderSourceDateRange.fromDate, toDate: orderSourceDateRange.toDate, statuses, locationIds: selectedLocationIds }))
		setIsLoadingOrderSource(false)
	}

	const getGeneralAnalyticsCancelledOrderReason = async () => {
		setIsLoadingCancelledOrderReason(true)
		await Promise.all([
			dispatch(fetchGeneralAnalyticsCancelledOrderReason({ fromDate: cancelledOrderReasonDateRange.fromDate, toDate: cancelledOrderReasonDateRange.toDate, locationIds: selectedLocationIds })),
			dispatch(fetchGeneralAnalyticsReturnedOrderReason({ fromDate: cancelledOrderReasonDateRange.fromDate, toDate: cancelledOrderReasonDateRange.toDate, locationIds: selectedLocationIds }))
		])
		setIsLoadingCancelledOrderReason(false)
	}

	const getGeneralAnalyticsDiscountAndAdvancePaymentOrderStats = async () => {
		setIsLoadingDiscountAndAdvancePaymentOrderStats(true)
		await dispatch(fetchGeneralAnalyticsDiscountAndAdvancePaymentOrderStats({ fromDate: discountAndAdvancePaymentOrderStatsDateRange.fromDate, toDate: discountAndAdvancePaymentOrderStatsDateRange.toDate, locationIds: selectedLocationIds }))
		setIsLoadingDiscountAndAdvancePaymentOrderStats(false)
	}

	const getECommerceDeliveryPartnerAnalyticsOrderRate = async () => {
		setIsLoadingDeliveryPartnersOrderRate(true)
		await dispatch(fetchECommerceDeliveryPartnerAnalyticsOrderRate({
			fromDate: deliveryPartnersOrderRateDateRange.fromDate,
			toDate: deliveryPartnersOrderRateDateRange.toDate,
			status: deliveryPartnerOrderStatusFilter.key,
			locationIds: selectedLocationIds
		}))
		setIsLoadingDeliveryPartnersOrderRate(false)
	}

	const getTopSellingProducts = async () => {
		setIsLoadingProducts(true)
		const params = {
			status: topProductsStatus.key,
			variant: topProductsType.key === 'variants'
		}
		if (topProductsDateRange.fromDate && topProductsDateRange.toDate) {
			params.fromDate = topProductsDateRange.fromDate
			params.toDate = topProductsDateRange.toDate
		}
		params.locationIds = selectedLocationIds
		await dispatch(fetchTopSellingProductsInTimeRange(params))
		setIsLoadingProducts(false)
	}

	const downloadTopSellingProductsCsv = async () => {
		if (!permissions.exportAnalytics) {
			return
		}
		if (isDownloadingProducts) {
			return
		}
		setIsDownloadingProducts(true)
		const params = {
			status: topProductsStatus.key,
			variant: topProductsType.key === 'variants'
		}
		if (topProductsDateRange.fromDate && topProductsDateRange.toDate) {
			params.fromDate = topProductsDateRange.fromDate
			params.toDate = topProductsDateRange.toDate
		}
		await Products.downloadTopSellerCsv(params)
		setIsDownloadingProducts(false)
	}

	const downloadTopCustomersCsv = async () => {
		if (!permissions.exportDistributors) {
			return
		}
		if (isDownloadingCustomers) {
			return
		}
		setIsDownloadingCustomers(true)
		const { filter, fromDate, toDate, status, locationIds } = getCustomerSearchParams()
		await Distributors.downloadTopCustomers(filter, fromDate, toDate, status, locationIds)
		setIsDownloadingCustomers(false)
	}

	const downloadOrderSourcesCsv = async () => {
		if (isDownloadingOrderSources) {
			return
		}
		setIsDownloadingOrderSources(true)
		let statuses = []
		Object.keys(orderSourceStatusFilter).forEach(status => {
			if (orderSourceStatusFilter[status]) {
				statuses.push(status)
			}
		})
		await Dashboard.downloadGeneralAnalyticsOrderSourcesCsv({ fromDate: orderSourceDateRange.fromDate, toDate: orderSourceDateRange.toDate, statuses, locationIds: selectedLocationIds })
		setIsDownloadingOrderSources(false)
	}

	const getSalesAnalyticsOrderTrends = async () => {
		setIsLoadingSalesAnalyticsOrderTrends(true)
		await dispatch(fetchSalesAnalyticsOrders(generalOrderCountCurrentTimeTab, moment.tz.guess(), generalOrderCountStatusFilter.key, selectedLocationIds))
		setIsLoadingSalesAnalyticsOrderTrends(false)
	}

	const getSalesAnalyticsAverageBasketTrends = async () => {
		setIsLoadingSalesAnalyticsAverageBasketTrends(true)
		await dispatch(fetchSalesAnalyticsAverageBasket(generalAverageBasketCurrentTimeTab, moment.tz.guess(), averageBasketStatus.key, selectedLocationIds))
		setIsLoadingSalesAnalyticsAverageBasketTrends(false)
	}

	const getStatSearchParams = () => {
		const params = {}
		if (statPeriodDateRange.fromDate && statPeriodDateRange.toDate) {
			params.fromDate = statPeriodDateRange.fromDate
			params.toDate = statPeriodDateRange.toDate
		}
		let statuses = []
		Object.keys(orderStatusFilter).forEach(status => {
			if (orderStatusFilter[status]) {
				statuses.push(status)
			}
		})
		params.statuses = statuses
		return params
	}

	const getPaymentStatSearchParams = () => {
		const params = {}
		if (paymentStatDateRange.fromDate && paymentStatDateRange.toDate) {
			params.fromDate = paymentStatDateRange.fromDate
			params.toDate = paymentStatDateRange.toDate
		}
		return params
	}

	const getCustomerSearchParams = () => {
		const params = {}
		if (customerDateRange.fromDate && customerDateRange.toDate) {
			params.fromDate = customerDateRange.fromDate
			params.toDate = customerDateRange.toDate
		}
		params.status = customerStatusFilter.key
		params.filter = customerFilter.key
		params.locationIds = selectedLocationIds
		return params
	}

	const onTimePeriodSelected = (
		period,
		setPeriod
	) => {
		dateRangeChangeHandler(setPeriod, 'timePeriod', period)
		switch (period.key) {
			case 'weekly':
			case timeSpan.week: {
				const start = moment().subtract(7, 'days').startOf('day')
				const end = moment().endOf('day')
				dateRangeChangeHandler(setPeriod, 'fromDate', start.toDate())
				dateRangeChangeHandler(setPeriod, 'toDate', end.toDate())
				break
			}
			case timeSpan.currentWeek: {
				const start = moment().startOf('week')
				const end = moment().endOf('week')
				dateRangeChangeHandler(setPeriod, 'fromDate', start.toDate())
				dateRangeChangeHandler(setPeriod, 'toDate', end.toDate())
				break
			}
			case 'monthly':
			case timeSpan.month: {
				const start = moment().subtract(30, 'days').startOf('day')
				const end = moment().endOf('day')
				dateRangeChangeHandler(setPeriod, 'fromDate', start.toDate())
				dateRangeChangeHandler(setPeriod, 'toDate', end.toDate())
				break
			}
			case timeSpan.currentMonth: {
				const start = moment().startOf('month')
				const end = moment().endOf('month')
				dateRangeChangeHandler(setPeriod, 'fromDate', start.toDate())
				dateRangeChangeHandler(setPeriod, 'toDate', end.toDate())
				break
			}
			case timeSpan.year: {
				const start = moment().startOf('year')
				const end = moment().endOf('year')
				dateRangeChangeHandler(setPeriod, 'fromDate', start.toDate())
				dateRangeChangeHandler(setPeriod, 'toDate', end.toDate())
				break
			}
			case timeSpan.lifetime: {
				dateRangeChangeHandler(setPeriod, 'fromDate', null)
				dateRangeChangeHandler(setPeriod, 'toDate', null)
				break
			}
			case timeSpan.custom:
				break
			case timeSpan.yesterday: {
				const start = moment().subtract(1, 'days').startOf('day')
				const end = moment().subtract(1, 'days').endOf('day')
				dateRangeChangeHandler(setPeriod, 'fromDate', start.toDate())
				dateRangeChangeHandler(setPeriod, 'toDate', end.toDate())
				break
			}
			case 'daily':
			case timeSpan.today:
			default: {
				const start = moment().startOf('day')
				const end = moment().endOf('day')
				dateRangeChangeHandler(setPeriod, 'fromDate', start.toDate())
				dateRangeChangeHandler(setPeriod, 'toDate', end.toDate())
				break
			}
		}
	}

	const getOrderCountTimeWiseXAxisValue = (d) => {
		let xValue = ''
		if (generalOrderCountCurrentTimeTab === 'daily') {
			xValue = +d.split('-')[2]
		} else if (generalOrderCountCurrentTimeTab === 'weekly') {
			xValue = +d.split('-')[1]
		} else if (generalOrderCountCurrentTimeTab === 'monthly') {
			xValue = +d.split('-')[1]
		} else if (generalOrderCountCurrentTimeTab === 'annually') {
			xValue = +d
		}
		return isNaN(xValue) ? '' : xValue
	}

	const getAverageBasketTimeWiseXAxisValue = (d) => {
		let xValue = ''
		if (generalAverageBasketCurrentTimeTab === 'daily') {
			xValue = +d.split('-')[2]
		} else if (generalAverageBasketCurrentTimeTab === 'weekly') {
			xValue = +d.split('-')[1]
		} else if (generalAverageBasketCurrentTimeTab === 'monthly') {
			xValue = +d.split('-')[1]
		} else if (generalAverageBasketCurrentTimeTab === 'annually') {
			xValue = +d
		}
		return isNaN(xValue) ? '' : xValue
	}

	const sideScroll = (
		element,
		speed,
		distance,
		step
	) => {
		let scrollAmount = 0
		const slideTimer = setInterval(() => {
			element.scrollLeft += step
			scrollAmount += Math.abs(step)
			if (scrollAmount >= distance) {
				clearInterval(slideTimer)
			}
		}, speed)
	}

	const getOrderStatus = (subStatus) =>
		subStatus.toLowerCase() === orderStatuses.SALES_ORDER_PAYMENT_DUE_STATUS ||
			subStatus.toLowerCase() === orderStatuses.SALES_ORDER_PAYMENT_COLLECTED_STATUS
			? orderStatuses.SALES_ORDER_COMPLETED_STATUS
			: orderStatuses.SALES_ORDER_FLAGGED_STATUS

	const applyFilterAndRedirect = (status, subStatus) => {
		applyFilter({
			statuses: [status.toLowerCase()],
			creationFromDate: statPeriodDateRange.fromDate,
			creationToDate: statPeriodDateRange.toDate
		})
		router.push(`${paths.SALES_ORDERS}?status=${status.toLowerCase()}${subStatus ? `&subStatus=${subStatus.toLowerCase()}` : ''}`)
	}

	const isAllOrderStatusesChecked = (filteredOrderStatuses = {}) => Object.values(filteredOrderStatuses).every((value) => value === true)

	const getOrderStatusFilters = (isAllChecked = true) =>
		orderStatusOptions.reduce((acc, { value }) => ({ ...acc, [value]: isAllChecked }), {})

	const renderOrderRateCycleTimeBarChart = () => {
		const { orderCycleChart } = generalAnalyticsOrderCycleTime
		const orderCycleTimeTabData = getOrderCycleChartData(orderCycleChart)
		return (
			<div>
				<div className={styles.pieAndCycleGraphContainer}>
					<div className={styles.tabInOrderCycleAndPieContainer}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
								Order Cycle Time
								<Tooltip title='The order cycle time (in hour) is the cumulative time a set of sales orders spends in each state.' placement='top'>
									<img src='/img/info.svg' alt='Alert icon' />
								</Tooltip>
							</h3>
							<Button
								style={{ marginLeft: 12, border: 'none' }}
								icon={<ReloadOutlined />}
								onClick={() => getGeneralAnalyticsOrderCycleTime()}
								loading={isLoadingGeneralAnalyticsOrderCycleTime}
							/>
						</div>
						<div style={{ display: 'flex', marginLeft: 'auto' }}>
							<Dropdown
								overlayClassName={styles.overlay}
								trigger={['click']}
								overlay={() => {
									return (
										<Menu>
											{
												timePeriods.map(orderPeriod => {
													return (
														<Menu.Item
															key={orderPeriod.key}
															onClick={() => {
																onTimePeriodSelected(orderPeriod, setOrderCycleDateRange)
																setShowMasterFilterSelected(false)
															}}
														>
															{orderPeriod.label}
														</Menu.Item>
													)
												})
											}
										</Menu>
									)
								}}
							>
								<div
									className={styles.timeTabSelected}
								>
									{orderCycleDateRange.timePeriod.label}
									<DownOutlined style={{ marginLeft: 12 }} />
								</div>
							</Dropdown>
						</div>
					</div>
					<div className={styles.dateRangeAndDatePickerContainer}>
						{renderDateRange(orderCycleDateRange)}
						{orderCycleDateRange.timePeriod.key === timeSpan.custom && renderDatePicker(orderCycleDateRange, setOrderCycleDateRange)}
					</div>
					<div style={{ height: 320 }}>
						<ResponsiveBar
							data={orderCycleTimeTabData}
							keys={['value']}
							indexBy='id'
							colors={{ datum: 'data.color' }}
							colorBy='indexValue'
							margin={{ top: 24, right: 60, bottom: 24, left: 120 }}
							layout='horizontal'
							valueFormat={value => {
								if (value < 1) {
									return `${(value * 60).toFixed(2)} mins`
								} else {
									return `${value.toFixed(2)} hrs`
								}
							}}
							valueScale={{ type: 'linear' }}
							indexScale={{ type: 'band', round: true }}
							padding={0.5}
							axisRight={null}
							axisBottom={{
								tickSize: 0,
								tickPadding: 5,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 32
							}}
							axisLeft={{
								tickSize: 0,
								tickPadding: 25,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 40
							}}
							theme={{
								axis: {
									domain: {
										line: {
											stroke: '#777777',
											strokeWidth: 1
										}
									}
								}
							}}
							enableGridY={false}
							labelSkipWidth={8}
							labelSkipHeight={10}
							labelTextColor='#ffffff'
						/>
					</div>
				</div>
			</div>
		)
	}

	const renderOrderSourceRevenueBarChart = () => {
		const orderSourceRevenueData = []
		let totalRevenue = 0
		let totalCount = 0
		generalAnalyticsOrderSource.forEach(data => {
			orderSourceRevenueData.push({
				id: convertEnumToString(data.source.toLowerCase()),
				value: data.amount,
				count: data.count,
				color: orderSourceColors[data.source]
			})
			totalRevenue += data.amount
			totalCount += data.count
		})
		return (
			<div>
				<div className={styles.pieAndCycleGraphContainer}>
					<div className={styles.tabInOrderCycleAndPieContainer}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
							<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
								Order Value
								<Tooltip title='Order Value is based on the selected time period and the order statuses' placement='top'>
									<img src='/img/info.svg' alt='Alert icon' />
								</Tooltip>
							</h3>
						</div>
					</div>
					<div style={{ height: 306.5 }}>
						<ResponsiveBar
							data={orderSourceRevenueData}
							keys={['value']}
							indexBy='id'
							colors={{ datum: 'data.color' }}
							colorBy='indexValue'
							margin={{ top: 24, right: 60, bottom: 24, left: 120 }}
							layout='vertical'
							valueScale={{ type: 'linear' }}
							indexScale={{ type: 'band', round: true }}
							padding={0.5}
							axisRight={null}
							axisBottom={{
								tickSize: 0,
								tickPadding: 5,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 32
							}}
							axisLeft={{
								tickSize: 0,
								tickPadding: 25,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: -100,
								format: '.2s',
								legend: `Total Value: ${getFormattedCurrency(totalRevenue)}`
							}}
							valueFormat={value => {
								return `${value.toFixed(2)}`
							}}
							tooltip={({ data }) => {
								return (
									<div className={styles.tooltip}>
										<div style={{ fontWeight: 'bold', color: 'black', fontSize: 12 }}>{data.id}</div>
										<div>Order Count: {data.count}</div>
										<div style={{ fontWeight: 'bold', color: 'black' }}>Value: {getFormattedCurrency(data.value)}</div>
									</div>
								)
							}}
							theme={{
								axis: {
									domain: {
										line: {
											stroke: '#777777',
											strokeWidth: 1
										}
									}
								}
							}}
							enableGridY={false}
							labelSkipWidth={8}
							labelSkipHeight={10}
							labelTextColor='#ffffff'
						/>
					</div>
					<div className={styles.axisLabel}>{`Total Order Count: ${totalCount}`}</div>
				</div>
			</div>
		)
	}

	const renderOrderSourceChart = () => {
		totalOrderCountRef.current = generalAnalyticsOrderSource.reduce((acc, data) => acc + data.count, 0)
		const orderSourceData = generalAnalyticsOrderSource.map(data => {
			return {
				id: convertEnumToString(data.source.toLowerCase()),
				value: data.count,
				color: orderSourceColors[data.source]
			}
		})
		return (
			<div>
				<div className={styles.pieAndCycleGraphContainer}>
					<div className={styles.tabInOrderCycleAndPieContainer}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
							<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
								Order Count
								<Tooltip title='Order Count based on the selected time period and the order statuses.' placement='top'>
									<img src='/img/info.svg' alt='Alert icon' />
								</Tooltip>
							</h3>
						</div>
					</div>
					<ResponsivePie
						data={orderSourceData}
						colors={{ datum: 'data.color' }}
						height={300}
						margin={{ top: 20, right: 40, bottom: 60, left: 40 }}
						innerRadius={0.5}
						padAngle={0.7}
						cornerRadius={3}
						activeOuterRadiusOffset={8}
						borderWidth={1}
						borderColor={{
							from: 'color',
							modifiers: [
								[
									'darker',
									0.2
								]
							]
						}}
						tooltip={({ datum: { id, value } }) => {
							return (
								<div className={styles.tooltip}>
									<div style={{ fontWeight: 'bold', color: 'black', fontSize: 12 }}>{id}</div>
									<div>Order Count: {value}</div>
									<div>Percentage: {((+value / +totalOrderCountRef.current) * 100).toFixed(2)}%</div>
								</div>
							)
						}}
						arcLinkLabelsSkipAngle={10}
						arcLinkLabelsTextColor='#333333'
						arcLinkLabelsThickness={2}
						arcLinkLabelsColor={{ from: 'color' }}
						arcLabelsSkipAngle={10}
						arcLabelsTextColor='#ffffff'
						legends={[
							{
								anchor: 'left',
								direction: 'column',
								justify: false,
								translateX: 0,
								translateY: 0,
								itemsSpacing: 2,
								itemHeight: 18,
								itemTextColor: '#999',
								itemDirection: 'left-to-right',
								itemOpacity: 1,
								symbolSize: 8,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemTextColor: '#000'
										}
									}
								]
							}
						]}
					/>
					<div className={styles.axisLabel}>{`Total Order Count: ${totalOrderCountRef.current}`}</div>
				</div>
			</div>
		)
	}

	const renderCancelledOrderReasonChart = () => {
		let totalCount = generalAnalyticsCancelledOrderReason.reduce((acc, data) => acc + data._count.id, 0)
		const cancelledOrderReasonData = generalAnalyticsCancelledOrderReason.map(data => {
			return {
				id: data.flagReason,
				value: data._count.id,
				color: cancelledOrderReasonColors[data.flagReason]
			}
		})
		return (
			<div>
				<div className={styles.pieAndCycleGraphContainer}>
					<div className={styles.tabInOrderCycleAndPieContainer}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
								Cancelled Order
								<Tooltip title='The percentage of cancelled orders based on cancelling reasons and the selected time period.' placement='top'>
									<img src='/img/info.svg' alt='Alert icon' />
								</Tooltip>
							</h3>
						</div>
					</div>
					<ResponsivePie
						data={cancelledOrderReasonData}
						colors={{ datum: 'data.color' }}
						height={340}
						margin={{ top: 20, right: 40, bottom: 30, left: 40 }}
						innerRadius={0.5}
						padAngle={0.7}
						cornerRadius={3}
						activeOuterRadiusOffset={8}
						borderWidth={1}
						borderColor={{
							from: 'color',
							modifiers: [
								[
									'darker',
									0.2
								]
							]
						}}
						valueFormat={value => {
							const percentage = (value / totalCount) * 100.00
							return value !== null ? `${Number(percentage).toLocaleString(percentage, {
								minimumFractionDigits: 2
							})} %` : `${0} %`
						}}
						tooltip={({ datum: { id, value, formattedValue } }) => {
							return (
								<div className={styles.tooltip}>
									<div style={{ fontWeight: 'bold', color: 'black', fontSize: 12 }}>{id}</div>
									<div>Order Count: {value}</div>
									<div style={{ fontWeight: 'bold', color: 'black' }}>{formattedValue}</div>
								</div>
							)
						}}
						arcLinkLabelsSkipAngle={10}
						arcLinkLabelsTextColor='#333333'
						arcLinkLabelsThickness={2}
						arcLinkLabelsColor={{ from: 'color' }}
						arcLabelsSkipAngle={10}
						arcLabelsTextColor='#ffffff'
						legends={[
							{
								anchor: 'top-left',
								direction: 'column',
								justify: false,
								translateX: -40,
								translateY: 40,
								itemsSpacing: 10,
								itemWidth: 90,
								itemHeight: 18,
								itemTextColor: '#999',
								itemDirection: 'left-to-right',
								itemOpacity: 1,
								symbolSize: 15,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemTextColor: '#000'
										}
									}
								]
							}
						]}
					/>
					<div className={styles.axisLabel}>{`Total Order Count: ${totalCount}`}</div>
				</div>
			</div>
		)
	}

	const renderReturnedOrderReasonChart = () => {
		let totalCount = generalAnalyticsReturnedOrderReason.reduce((acc, data) => acc + data._count.id, 0)
		const returnedOrderReasonData = generalAnalyticsReturnedOrderReason.map(data => {
			return {
				id: data.flagReason,
				value: data._count.id,
				color: returnedOrderReasonColors[data.flagReason]
			}
		})
		return (
			<div>
				<div className={styles.pieAndCycleGraphContainer}>
					<div className={styles.tabInOrderCycleAndPieContainer}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
								Returned Order
								<Tooltip title='The percentage of returned orders based on returned reasons and the selected time period.' placement='top'>
									<img src='/img/info.svg' alt='Alert icon' />
								</Tooltip>
							</h3>
						</div>
					</div>
					<ResponsivePie
						data={returnedOrderReasonData}
						colors={{ datum: 'data.color' }}
						height={340}
						margin={{ top: 20, right: 40, bottom: 30, left: 40 }}
						innerRadius={0.5}
						padAngle={0.7}
						cornerRadius={3}
						activeOuterRadiusOffset={8}
						borderWidth={1}
						borderColor={{
							from: 'color',
							modifiers: [
								[
									'darker',
									0.2
								]
							]
						}}
						valueFormat={value => {
							const percentage = (value / totalCount) * 100.00
							return value !== null ? `${Number(percentage).toLocaleString(percentage, {
								minimumFractionDigits: 2
							})} %` : `${0} %`
						}}
						tooltip={({ datum: { id, value, formattedValue } }) => {
							return (
								<div className={styles.tooltip}>
									<div style={{ fontWeight: 'bold', color: 'black', fontSize: 12 }}>{id}</div>
									<div>Order Count: {value}</div>
									<div style={{ fontWeight: 'bold', color: 'black' }}>{formattedValue}</div>
								</div>
							)
						}}
						arcLinkLabelsSkipAngle={10}
						arcLinkLabelsTextColor='#333333'
						arcLinkLabelsThickness={2}
						arcLinkLabelsColor={{ from: 'color' }}
						arcLabelsSkipAngle={10}
						arcLabelsTextColor='#ffffff'
						legends={[
							{
								anchor: 'top-left',
								direction: 'column',
								justify: false,
								translateX: -40,
								translateY: 40,
								itemsSpacing: 10,
								itemWidth: 90,
								itemHeight: 18,
								itemTextColor: '#999',
								itemDirection: 'left-to-right',
								itemOpacity: 1,
								symbolSize: 15,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemTextColor: '#000'
										}
									}
								]
							}
						]}
					/>
					<div className={styles.axisLabel}>{`Total Order Count: ${totalCount}`}</div>
				</div>
			</div>
		)
	}

	const renderDeliveryPartnersOrderRateChart = () => {
		let totalCount = ecommerceDeliveryPartnerAnalyticsOrderRate.reduce((acc, data) => acc + data.count, 0)
		const deliveryPartnersOrderRateData = ecommerceDeliveryPartnerAnalyticsOrderRate.map(data => {
			return {
				id: deliveryPartnerRenderInfo[data.type].label,
				value: data.count,
				color: deliveryPartnerRenderInfo[data.type].color
			}
		})
		return (
			<div>
				<div className={styles.pieAndCycleGraphContainer}>
					<div className={styles.tabInOrderCycleAndPieContainer}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
								Delivery Partners&apos; Order Rate
								<Tooltip title='The percentage of In Transit orders by delivery partners based on total In Transit orders on the selected time period.' placement='top'>
									<img src='/img/info.svg' alt='Alert icon' />
								</Tooltip>
							</h3>
						</div>
					</div>
					<ResponsivePie
						data={deliveryPartnersOrderRateData}
						colors={{ datum: 'data.color' }}
						height={340}
						margin={{ top: 20, right: 40, bottom: 30, left: 40 }}
						innerRadius={0.5}
						padAngle={0.7}
						cornerRadius={3}
						activeOuterRadiusOffset={8}
						borderWidth={1}
						borderColor={{
							from: 'color',
							modifiers: [
								[
									'darker',
									0.2
								]
							]
						}}
						valueFormat={value => {
							const percentage = (value / totalCount) * 100.00
							return value !== null ? `${Number(percentage).toLocaleString(percentage, {
								minimumFractionDigits: 2
							})} %` : `${0} %`
						}}
						tooltip={({ datum: { id, value, formattedValue } }) => {
							return (
								<div className={styles.tooltip}>
									<div style={{ fontWeight: 'bold', color: 'black', fontSize: 12 }}>{id}</div>
									<div>Order Count: {value}</div>
									<div style={{ fontWeight: 'bold', color: 'black' }}>{formattedValue}</div>
								</div>
							)
						}}
						arcLinkLabelsSkipAngle={10}
						arcLinkLabelsTextColor='#333333'
						arcLinkLabelsThickness={2}
						arcLinkLabelsColor={{ from: 'color' }}
						arcLabelsSkipAngle={10}
						arcLabelsTextColor='#ffffff'
						legends={[
							{
								anchor: 'top-left',
								direction: 'column',
								justify: false,
								translateX: -40,
								translateY: 40,
								itemsSpacing: 10,
								itemWidth: 90,
								itemHeight: 18,
								itemTextColor: '#999',
								itemDirection: 'left-to-right',
								itemOpacity: 1,
								symbolSize: 15,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemTextColor: '#000'
										}
									}
								]
							}
						]}
					/>
					<div className={styles.axisLabel}>{`Total Order Count: ${totalCount}`}</div>
				</div>
			</div>
		)
	}

	const renderDeliveryPartnersSalesValueChart = () => {
		const deliveryPartnersSalesValueData = []
		let totalSalesValue = 0
		let totalCount = 0
		ecommerceDeliveryPartnerAnalyticsOrderRate.forEach(data => {
			deliveryPartnersSalesValueData.push({
				id: deliveryPartnerRenderInfo[data.type].label,
				value: data.amount,
				count: data.count,
				color: deliveryPartnerRenderInfo[data.type].color
			})
			totalSalesValue += data.amount
			totalCount += data.count
		})
		return (
			<div>
				<div className={styles.pieAndCycleGraphContainer}>
					<div className={styles.tabInOrderCycleAndPieContainer}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
								Delivery Partners&apos; Sales Value
								<Tooltip title='The sales value handled by each delivery partner as a percentage of total In Transit Orders sales value on the selected time period.' placement='top'>
									<img src='/img/info.svg' alt='Alert icon' />
								</Tooltip>
							</h3>
						</div>
					</div>
					<div style={{ height: 340 }}>
						<ResponsiveBar
							data={deliveryPartnersSalesValueData}
							keys={['value']}
							indexBy='id'
							colors={{ datum: 'data.color' }}
							colorBy='indexValue'
							margin={{ top: 24, right: 60, bottom: 20, left: 120 }}
							layout='vertical'
							valueScale={{ type: 'linear' }}
							indexScale={{ type: 'band', round: true }}
							padding={0.5}
							axisRight={null}
							axisBottom={{
								tickSize: 0,
								tickPadding: 5,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 32
							}}
							axisLeft={{
								tickSize: 0,
								tickPadding: 25,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: -100,
								format: '.2s',
								legend: `Total Value: ${getFormattedCurrency(totalSalesValue)}`
							}}
							tooltip={({ data }) => {
								return (
									<div className={styles.tooltip}>
										<div style={{ fontWeight: 'bold', color: 'black', fontSize: 12 }}>{data.id}</div>
										<div>Order Count: {data.count}</div>
										<div style={{ fontWeight: 'bold', color: 'black' }}>Value: {getFormattedCurrency(data.value)}</div>
									</div>
								)
							}}
							theme={{
								axis: {
									domain: {
										line: {
											stroke: '#777777',
											strokeWidth: 1
										}
									}
								}
							}}
							enableGridY={false}
							labelSkipWidth={8}
							labelSkipHeight={10}
							labelTextColor='#ffffff'
						/>
					</div>
					<div className={styles.axisLabel}>{`Total Order Count: ${totalCount}`}</div>
				</div>
			</div>
		)
	}

	const renderDiscountAndAdvancePaymentOrderCountChart = () => {
		const totalCount = {
			'DISCOUNT_ALLOWED': 0,
			'ADVANCED_PAYMENT': 0
		}
		const discountAndAdvancePaymentCountChartData = generalAnalyticsDiscountAndAdvancePaymentOrderCount.map((data) => {
			totalCount[data.type] += data.count
			return {
				id: discountAndAdvancedPayments[data.type].label,
				value: data.count,
				color: discountAndAdvancedPayments[data.type].color
			}
		})
		return (
			<div>
				<div className={styles.pieAndCycleGraphContainer}>
					<div className={styles.tabInOrderCycleAndPieContainer}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
							<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
								Discount Allowed & Advanced Payment Order Count
							</h3>
						</div>
					</div>
					<div style={{ height: 306.5 }}>
						<ResponsiveBar
							data={discountAndAdvancePaymentCountChartData}
							keys={['value']}
							indexBy='id'
							colors={{ datum: 'data.color' }}
							colorBy='indexValue'
							margin={{ top: 24, right: 60, bottom: 24, left: 120 }}
							layout='vertical'
							valueScale={{ type: 'linear' }}
							indexScale={{ type: 'band', round: true }}
							padding={0.5}
							axisRight={null}
							axisBottom={{
								tickSize: 0,
								tickPadding: 5,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 32
							}}
							axisLeft={{
								tickSize: 0,
								tickPadding: 25,
								tickRotation: 0,
								legend: 'Count',
								legendPosition: 'middle',
								legendOffset: -65,
								format: (value) => Number.isInteger(value) ? value.toString() : ''
							}}
							tooltip={({ data }) => {
								return (
									<div className={styles.tooltip}>
										<div style={{ fontWeight: 'bold', color: 'black', fontSize: 12 }}>{data.id}</div>
										<div>Order Count: {data.value}</div>
									</div>
								)
							}}
							theme={{
								axis: {
									domain: {
										line: {
											stroke: '#777777',
											strokeWidth: 1
										}
									}
								}
							}}
							enableGridY={false}
							labelSkipWidth={8}
							labelSkipHeight={10}
							labelTextColor='#ffffff'
						/>
					</div>
					<div className={styles.discountChartAxisLabelContainer}>
						<div className={styles.axisLabel}>{`Total Discount Allowed Orders Count: ${totalCount['DISCOUNT_ALLOWED']}`}</div>
						<div className={styles.axisLabel}>{`Total Advanced Payment Orders Count: ${totalCount['ADVANCED_PAYMENT']}`}</div>
					</div>
				</div>
			</div>
		)
	}

	const renderDiscountAndAdvancePaymentOrderValueChart = () => {
		const totalValue = {
			'DISCOUNT_ALLOWED': 0,
			'ADVANCED_PAYMENT': 0
		}
		const discountAndAdvancePaymentValueChartData = generalAnalyticsDiscountAndAdvancePaymentOrderValue.map((data) => {
			totalValue[data.type] += data.value
			return {
				id: discountAndAdvancedPayments[data.type].label,
				value: parseFloat(new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: false }).format(+data.value)),
				color: discountAndAdvancedPayments[data.type].color
			}
		})
		return (
			<div>
				<div className={styles.pieAndCycleGraphContainer}>
					<div className={styles.tabInOrderCycleAndPieContainer}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
							<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
								Discount Allowed & Advanced Payment Order Value
							</h3>
						</div>
					</div>
					<div style={{ height: 306.5 }}>
						<ResponsiveBar
							data={discountAndAdvancePaymentValueChartData}
							keys={['value']}
							indexBy='id'
							colors={{ datum: 'data.color' }}
							colorBy='indexValue'
							margin={{ top: 24, right: 60, bottom: 24, left: 120 }}
							layout='vertical'
							valueScale={{ type: 'linear' }}
							indexScale={{ type: 'band', round: true }}
							padding={0.5}
							axisRight={null}
							axisBottom={{
								tickSize: 0,
								tickPadding: 5,
								tickRotation: 0,
								legendPosition: 'middle',
								legendOffset: 32
							}}
							axisLeft={{
								tickSize: 0,
								tickPadding: 25,
								tickRotation: 0,
								legend: 'BDT',
								legendPosition: 'middle',
								legendOffset: -65,
								format: (value) => value % 5 === 0 ? value.toString() : ''
							}}
							tooltip={({ data }) => {
								return (
									<div className={styles.tooltip}>
										<div style={{ fontWeight: 'bold', color: 'black', fontSize: 12 }}>{data.id}</div>
										<div>Order Value: {data.value}</div>
									</div>
								)
							}}
							theme={{
								axis: {
									domain: {
										line: {
											stroke: '#777777',
											strokeWidth: 1
										}
									}
								}
							}}
							enableGridY={false}
							labelSkipWidth={8}
							labelSkipHeight={10}
							labelTextColor='#ffffff'
						/>
					</div>
					<div className={styles.discountChartAxisLabelContainer}>
						<div className={styles.axisLabel}>{`Total Discount Allowed Orders Value: ${getFormattedCurrency(totalValue['DISCOUNT_ALLOWED'])}`}</div>
						<div className={styles.axisLabel}>{`Total Advanced Payment Orders Value: ${getFormattedCurrency(totalValue['ADVANCED_PAYMENT'])}`}</div>
					</div>
				</div>
			</div>
		)
	}

	const renderOrderRatePercentageChart = () => {
		const generalPieChartData = getOrderRatePieChartData(generalAnalyticsOrderRate.todayOrderRate)
		return (
			<div>
				<div className={`${styles.pieAndCycleGraphContainer} pie-chart-container`}>
					<div className={styles.tabInOrderCycleAndPieContainer}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
								Order Status Percentage
								<Tooltip title='The percentage of orders in different stages based on time period.' placement='top'>
									<img src='/img/info.svg' alt='Alert icon' />
								</Tooltip>
							</h3>
							<Button
								style={{ marginLeft: 12, border: 'none' }}
								icon={<ReloadOutlined />}
								onClick={() => getGeneralAnalyticsOrderRate()}
								loading={isLoadingGeneralAnalyticsOrderRate}
							/>
						</div>
						<div style={{ display: 'flex', marginLeft: 'auto' }}>
							<Dropdown
								overlayClassName={styles.overlay}
								trigger={['click']}
								overlay={() => {
									return (
										<Menu>
											{
												timePeriods.map(orderPeriod => {
													return (
														<Menu.Item
															key={orderPeriod.key}
															onClick={() => {
																onTimePeriodSelected(orderPeriod, setOrderRateDateRange)
																setShowMasterFilterSelected(false)
															}}
														>
															{orderPeriod.label}
														</Menu.Item>
													)
												})
											}
										</Menu>
									)
								}}
							>
								<div
									className={styles.timeTabSelected}
								>
									{orderRateDateRange.timePeriod.label}
									<DownOutlined style={{ marginLeft: 12 }} />
								</div>
							</Dropdown>
						</div>
					</div>
					<div className={styles.dateRangeAndDatePickerContainer}>
						{renderDateRange(orderRateDateRange)}
						{orderRateDateRange.timePeriod.key === timeSpan.custom && renderDatePicker(orderRateDateRange, setOrderRateDateRange)}
					</div>

					<ResponsivePie
						data={generalPieChartData}
						colors={{ datum: 'data.color' }}
						height={370}
						margin={{ top: 40, right: 80, bottom: 60, left: 160 }}
						innerRadius={0.5}
						padAngle={0.7}
						cornerRadius={3}
						activeOuterRadiusOffset={8}
						borderWidth={1}
						borderColor={{
							from: 'color',
							modifiers: [
								[
									'darker',
									0.2
								]
							]
						}}
						valueFormat={value => {
							return value !== null ? `${Number(value).toLocaleString(value, {
								minimumFractionDigits: 2
							})} %` : `${0} %`
						}}
						arcLinkLabelsSkipAngle={10}
						arcLinkLabelsTextColor='#333333'
						arcLinkLabelsThickness={2}
						arcLinkLabelsColor={{ from: 'color' }}
						arcLabelsSkipAngle={10}
						arcLabelsTextColor='#ffffff'
						legends={[
							{
								anchor: 'top-left',
								direction: 'column',
								justify: false,
								translateX: -160,
								translateY: 0,
								itemsSpacing: 10,
								itemWidth: 90,
								itemHeight: 18,
								itemTextColor: '#999',
								itemDirection: 'left-to-right',
								itemOpacity: 1,
								symbolSize: 18,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemTextColor: '#000'
										}
									}
								]
							}
						]}
					/>
				</div>
			</div>)
	}

	const renderTopProducts = () => {
		const productMap = {}
		topSellingProductsInTimeRange.forEach(product => {
			productMap[product.id] = product
		})
		return (
			showGraphView ?
				<div className={styles.productGraphContainer}>
					{
						topSellingProductsInTimeRange.length > 0 ?
							<ResponsiveBar
								colors={['#288EA5', '#FF5959', '#22E6C2', '#F6B44F', '#18A188', '#FF8B8B', '#6EB9C9', '#C48F3F', '#75F0D9', '#207285']}
								keys={topSellingProductsInTimeRange.map(({ id }) => productMap[id].name)}
								legends={[
									{
										dataFrom: 'keys',
										anchor: 'bottom-right',
										direction: 'column',
										justify: false,
										translateX: 120,
										translateY: 0,
										itemsSpacing: 2,
										itemWidth: 100,
										itemHeight: 20,
										itemDirection: 'left-to-right',
										symbolSize: 20
									}
								]}
								data={topSellingProductsInTimeRange.map(product => ({
									productId: product.id,
									value: product.totalQuantity,
									[productMap[product.id].name]: product.totalQuantity
								}))}
								indexBy='productId'
								margin={{ top: 10, right: 400, bottom: 5, left: 60 }}
								padding={0.3}
								axisTop={null}
								axisRight={null}
								enableLabel={false}
								axisBottom={null}
								axisLeft={{
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legend: 'Total Quantity',
									legendPosition: 'middle',
									legendOffset: -55
								}}
								theme={{
									axis: {
										legend: {
											text: {
												fill: '#64748B'
											}
										},
										fontSize: '12px'
									}
								}}
								tooltipLabel={(data) => `${productMap[data.indexValue].name}`}
								tooltip={data => {
									const product = productMap[data.indexValue]
									const weightSpecification = product.productSpecifications.find(spec => spec.specificationKey === 'weight')
									const volumeSpecification = product.productSpecifications.find(spec => spec.specificationKey === 'volume')
									return (
										<div
											style={{
												background: 'black',
												color: 'white',
												padding: '4px 8px'
											}}
										>
											<span className={styles.productName} style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
												Product name:
												<div className={styles.productPrice} style={{ marginLeft: 4, color: 'white' }}>{product.name}</div>
											</span>
											{
												weightSpecification && <div className={styles.productWeight} style={{ color: 'white' }}>{`Weight: ${weightSpecification.value} ${weightSpecification.unit}`}</div>
											}
											{
												volumeSpecification && <div className={styles.productWeight} style={{ color: 'white' }}>{`Volume: ${volumeSpecification.value} ${volumeSpecification.unit}`}</div>
											}
											{
												topProductsType.key === 'variants' && <div className={styles.productWeight} style={{ color: 'white' }}>{`Price: ${Number(product.salePrice) > 0 ? getFormattedCurrency(product.salePrice) : getFormattedCurrency(product.price)}`}</div>
											}
											<span className={styles.productWeight} style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
												Total Sales Quantity:
												<div className={styles.productPrice} style={{ marginLeft: 4, color: 'white' }}>{product.totalQuantity}</div>
											</span>
											<span className={styles.productWeight} style={{ display: 'flex', color: 'white' }}>
												Total Sales Amount:
											</span>
											<div className={styles.productPrice} style={{ color: 'white' }}>{getFormattedCurrency(product.totalSalesAmount)}</div>
										</div>
									)
								}}
								labelSkipHeight={12}
								role='application'
								barAriaLabel={e => {
									return `${e.id} : ${e.formattedValue} on date: ${e.indexValue}`
								}}
							/> :
							<CustomEmptySecondary style={{ marginTop: 0 }} />
					}
				</div> :
				<div style={{ position: 'relative' }}>
					<div className={styles.productsContainer} ref={productsRef}>
						{
							isLoadingProducts ?
								<div className={styles.productItemSpinnerContainer}>
									<Spin />
								</div> :
								topSellingProductsInTimeRange.length > 0 ?
									topSellingProductsInTimeRange.map(item => {
										const weightSpecification = item.productSpecifications.find(spec => spec.specificationKey === 'weight')
										const volumeSpecification = item.productSpecifications.find(spec => spec.specificationKey === 'volume')
										return (
											<div
												key={item.id}
												className={styles.productItemContainer}
												onClick={() => router.push(`products/${item.id}`)}
											>
												<div className={styles.productImageContainer}>
													{
														item.imageUrls && item.imageUrls[0] ?
															<img
																className={styles.productImage}
																placeholder={true}
																src={item.imageUrls[0]}
																referrerPolicy='no-referrer'
															/> :
															<Skeleton.Image style={{ width: 176, height: 176 }} />
													}
												</div>
												<div className={styles.productName}>{item.name}</div>
												{
													topProductsType.key === 'variants' &&
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														{
															weightSpecification && <div className={styles.productWeight}>{`Weight: ${weightSpecification.value} ${weightSpecification.unit}`}</div>
														}
														{
															volumeSpecification && <div className={styles.productWeight}>{`Volume: ${volumeSpecification.value} ${volumeSpecification.unit}`}</div>
														}
														<div className={styles.productWeight}>{`Price: ${Number(item.salePrice) > 0 ? getFormattedCurrency(item.salePrice) : getFormattedCurrency(item.price)}`}</div>
													</div>
												}
												{
													topProductsType.key !== 'variants' &&
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														<span className={styles.productWeight} style={{ display: 'flex', alignItems: 'center' }}>
															Total Variants:
															<div className={styles.productPrice} style={{ marginLeft: 4 }}>{item.variantCount}</div>
														</span>
														<span className={styles.productWeight} style={{ display: 'flex', alignItems: 'center' }}>
															Total Weight:
															<div className={styles.productPrice} style={{ marginLeft: 4 }}>{`${(+item.totalWeight).toFixed(2)} kg`}</div>
														</span>
														<span className={styles.productWeight} style={{ display: 'flex', alignItems: 'center' }}>
															Total Volume:
															<div className={styles.productPrice} style={{ marginLeft: 4 }}>{`${(+item.totalVolume).toFixed(2)} L`}</div>
														</span>
													</div>
												}
												<span className={styles.productWeight} style={{ display: 'flex', alignItems: 'center' }}>
													Total Sales Quantity:
													<div className={styles.productPrice} style={{ marginLeft: 4 }}>{item.totalQuantity}</div>
												</span>
												<span className={styles.productWeight} style={{ display: 'flex' }}>
													Total Sales Amount:
												</span>
												<div className={styles.productPrice}>{getFormattedCurrency(item.totalSalesAmount)}</div>
											</div>
										)
									}) :
									<CustomEmptySecondary />
						}
					</div>
					{
						topSellingProductsInTimeRange.length > 0 &&
						<Button
							className={`${styles.slideButton} ${styles.slideButtonLeft}`}
							icon={<LeftOutlined />}
							onClick={() => sideScroll(productsRef.current, 50, 200, -20)}
						/>
					}
					{
						topSellingProductsInTimeRange.length > 0 &&
						<Button
							className={`${styles.slideButton} ${styles.slideButtonRight}`}
							icon={<RightOutlined />}
							onClick={() => sideScroll(productsRef.current, 50, 200, 20)}
						/>
					}
				</div>
		)
	}

	const renderSalesCountChart = () => {
		let generalOrderCountData = salesAnalyticsOrders.salesOrderCountTrend.map(data => ({ date: data.day, value: data.count }))
		if (generalOrderCountCurrentTimeTab === 'weekly') {
			generalOrderCountData = salesAnalyticsOrders.salesOrderCountTrendWeekly.map(data => ({ date: data.week, value: data.count }))
		} else if (generalOrderCountCurrentTimeTab === 'monthly') {
			generalOrderCountData = salesAnalyticsOrders.salesOrderCountTrendMonthly.map(data => ({ date: data.month, value: data.count }))
		} else if (generalOrderCountCurrentTimeTab === 'annually') {
			generalOrderCountData = salesAnalyticsOrders.salesOrderCountTrendYearly.map(data => ({ date: data.year, value: data.count }))
		}
		return (
			<div className={styles.graphContainerOrderCount}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
						Order Trend
						<Tooltip title='Order quantity trend based on the selected order status and the time period.' placement='top'>
							<img src='/img/info.svg' alt='Alert icon' />
						</Tooltip>
					</h3>
				</div>
				<ResponsiveBar
					colors={['#298EA4']}
					data={generalOrderCountData}
					indexBy='date'
					margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
					padding={0.3}
					axisTop={null}
					axisRight={null}
					enableLabel={false}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendPosition: 'middle',
						legendOffset: 32,
						format: (d) => getOrderCountTimeWiseXAxisValue(d)
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 1,
						tickRotation: 0,
						legend: 'Count',
						legendPosition: 'middle',
						legendOffset: -55
					}}
					theme={{
						axis: {
							legend: {
								text: {
									fill: '#64748B'
								}
							},
							fontSize: '12px'
						}
					}}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
					tooltipLabel={data => generalOrderCountCurrentTimeTab !== 'daily' ? `${generalOrderCountCurrentTimeTab === 'weekly' ? 'Week' : generalOrderCountCurrentTimeTab === 'monthly' ? 'Month' : 'Year'} ${getOrderCountTimeWiseXAxisValue(data.indexValue)}` : data.indexValue}
					role='application'
					barAriaLabel={e => {
						return `${e.id} : ${e.formattedValue} on date: ${e.indexValue}`
					}}
				/>
			</div>
		)
	}

	const renderSalesAmountChart = () => {
		let generalOrderCountData = salesAnalyticsOrders.salesOrderCountTrend.map(data => ({ date: data.day, value: Number(data.amount).toFixed(2) }))
		if (generalOrderCountCurrentTimeTab === 'weekly') {
			generalOrderCountData = salesAnalyticsOrders.salesOrderCountTrendWeekly.map(data => ({ date: data.week, value: Number(data.amount).toFixed(2) }))
		} else if (generalOrderCountCurrentTimeTab === 'monthly') {
			generalOrderCountData = salesAnalyticsOrders.salesOrderCountTrendMonthly.map(data => ({ date: data.month, value: Number(data.amount).toFixed(2) }))
		} else if (generalOrderCountCurrentTimeTab === 'annually') {
			generalOrderCountData = salesAnalyticsOrders.salesOrderCountTrendYearly.map(data => ({ date: data.year, value: Number(data.amount).toFixed(2) }))
		}
		return (
			<div className={styles.graphContainerOrderAmount}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
						Sales Trend
						<Tooltip title='Order value trend based on the selected order status and the time period.' placement='top'>
							<img src='/img/info.svg' alt='Alert icon' />
						</Tooltip>
					</h3>
				</div>
				<ResponsiveBar
					colors={['#298EA4']}
					data={generalOrderCountData}
					indexBy='date'
					margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
					padding={0.3}
					axisTop={null}
					axisRight={null}
					enableLabel={false}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendPosition: 'middle',
						legendOffset: 32,
						format: (d) => getOrderCountTimeWiseXAxisValue(d)
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 1,
						tickRotation: 0,
						legend: getCurrencyCode(),
						legendPosition: 'middle',
						legendOffset: -55,
						format: '.2s'
					}}
					theme={{
						axis: {
							legend: {
								text: {
									fill: '#64748B'
								}
							},
							fontSize: '12px'
						}
					}}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
					tooltipLabel={data => generalOrderCountCurrentTimeTab !== 'daily' ? `${generalOrderCountCurrentTimeTab === 'weekly' ? 'Week' : generalOrderCountCurrentTimeTab === 'monthly' ? 'Month' : 'Year'} ${getOrderCountTimeWiseXAxisValue(data.indexValue)}` : data.indexValue}
					role='application'
					barAriaLabel={e => {
						return `${e.id} : ${e.formattedValue} on date: ${e.indexValue}`
					}}
				/>
			</div>
		)
	}

	const renderAverageBasketUnitChart = () => {
		let generalOrderCountData = salesAnalyticsAverageBasket.averageBasketTrend.map(data => {
			const value = isNaN(data.quantity / data.count) ? 0 : +(data.quantity / data.count).toFixed(2)
			return { date: data.day, value, quantity: data.quantity, count: data.count }
		})
		if (generalAverageBasketCurrentTimeTab === 'weekly') {
			generalOrderCountData = salesAnalyticsAverageBasket.averageBasketTrendWeekly.map(data => {
				const value = isNaN(data.quantity / data.count) ? 0 : +(data.quantity / data.count).toFixed(2)
				return { date: data.week, value, quantity: data.quantity, count: data.count }
			})
		} else if (generalAverageBasketCurrentTimeTab === 'monthly') {
			generalOrderCountData = salesAnalyticsAverageBasket.averageBasketTrendMonthly.map(data => {
				const value = isNaN(data.quantity / data.count) ? 0 : +(data.quantity / data.count).toFixed(2)
				return { date: data.month, value, quantity: data.quantity, count: data.count }
			})
		} else if (generalAverageBasketCurrentTimeTab === 'annually') {
			generalOrderCountData = salesAnalyticsAverageBasket.averageBasketTrendYearly.map(data => {
				const value = isNaN(data.quantity / data.count) ? 0 : +(data.quantity / data.count).toFixed(2)
				return { date: data.year, value, quantity: data.quantity, count: data.count }
			})
		}
		return (
			<div className={styles.graphContainerOrderCount}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
						Average Basket Unit
						<Tooltip title='Average Basket Unit based on the total order and product quantity of the selected status and the selected time period.' placement='top'>
							<img src='/img/info.svg' alt='Alert icon' />
						</Tooltip>
					</h3>
				</div>
				<ResponsiveBar
					colors={['#298EA4']}
					data={generalOrderCountData}
					indexBy='date'
					margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
					padding={0.3}
					axisTop={null}
					axisRight={null}
					enableLabel={false}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendPosition: 'middle',
						legendOffset: 32,
						format: (d) => getAverageBasketTimeWiseXAxisValue(d)
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 1,
						tickRotation: 0,
						legend: 'Count',
						legendPosition: 'middle',
						legendOffset: -55
					}}
					theme={{
						axis: {
							legend: {
								text: {
									fill: '#64748B'
								}
							},
							fontSize: '12px'
						}
					}}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
					tooltipLabel={data => generalAverageBasketCurrentTimeTab !== 'daily' ? `${generalAverageBasketCurrentTimeTab === 'weekly' ? 'Week' : generalAverageBasketCurrentTimeTab === 'monthly' ? 'Month' : 'Year'} ${getAverageBasketTimeWiseXAxisValue(data.indexValue)}` : data.indexValue}
					role='application'
					barAriaLabel={e => {
						return `${e.id} : ${e.formattedValue} on date: ${e.indexValue}`
					}}
					tooltip={({ data }) => {
						return (
							<div className={styles.tooltip}>
								<b>Order Count:</b>
								<div>{data.count}</div>
								<b>Average Basket Unit:</b>
								<div>{data.quantity} / {data.count}</div>
								<b style={{ color: 'black' }}>= {data.value}</b>
							</div>
						)
					}}
				/>
			</div>
		)
	}

	const renderAverageBasketValueChart = () => {
		let generalOrderCountData = salesAnalyticsAverageBasket.averageBasketTrend.map(data => {
			const value = isNaN(+data.amount / data.count) ? 0 : +(+data.amount / data.count).toFixed(2)
			return { date: data.day, value, amount: +data.amount, count: data.count }
		})
		if (generalAverageBasketCurrentTimeTab === 'weekly') {
			generalOrderCountData = salesAnalyticsAverageBasket.averageBasketTrendWeekly.map(data => {
				const value = isNaN(+data.amount / data.count) ? 0 : +(+data.amount / data.count).toFixed(2)
				return { date: data.week, value, amount: +data.amount, count: data.count }
			})
		} else if (generalAverageBasketCurrentTimeTab === 'monthly') {
			generalOrderCountData = salesAnalyticsAverageBasket.averageBasketTrendMonthly.map(data => {
				const value = isNaN(+data.amount / data.count) ? 0 : +(+data.amount / data.count).toFixed(2)
				return { date: data.month, value, amount: +data.amount, count: data.count }
			})
		} else if (generalAverageBasketCurrentTimeTab === 'annually') {
			generalOrderCountData = salesAnalyticsAverageBasket.averageBasketTrendYearly.map(data => {
				const value = isNaN(+data.amount / data.count) ? 0 : +(+data.amount / data.count).toFixed(2)
				return { date: data.year, value, amount: +data.amount, count: data.count }
			})
		}
		return (
			<div className={styles.graphContainerOrderAmount}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
						Average Basket Value
						<Tooltip title='Average Basket Value based on the total order and product quantity of the selected status and the selected time period.' placement='top'>
							<img src='/img/info.svg' alt='Alert icon' />
						</Tooltip>
					</h3>
				</div>
				<ResponsiveBar
					colors={['#298EA4']}
					data={generalOrderCountData}
					indexBy='date'
					margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
					padding={0.3}
					axisTop={null}
					axisRight={null}
					enableLabel={false}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendPosition: 'middle',
						legendOffset: 32,
						format: (d) => getAverageBasketTimeWiseXAxisValue(d)
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 1,
						tickRotation: 0,
						legend: getCurrencyCode(),
						legendPosition: 'middle',
						legendOffset: -55,
						format: '.2s'
					}}
					theme={{
						axis: {
							legend: {
								text: {
									fill: '#64748B'
								}
							},
							fontSize: '12px'
						}
					}}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
					tooltipLabel={data => generalAverageBasketCurrentTimeTab !== 'daily' ? `${generalAverageBasketCurrentTimeTab === 'weekly' ? 'Week' : generalAverageBasketCurrentTimeTab === 'monthly' ? 'Month' : 'Year'} ${getAverageBasketTimeWiseXAxisValue(data.indexValue)}` : data.indexValue}
					role='application'
					barAriaLabel={e => {
						return `${e.id} : ${e.formattedValue} on date: ${e.indexValue}`
					}}
					tooltip={({ data }) => {
						return (
							<div className={styles.tooltip}>
								<b>Total Order Value:</b>
								<div>{getFormattedCurrency(data.amount)}</div>
								<b>Average Basket Value:</b>
								<div>{getFormattedCurrency(data.amount)} / {data.count}</div>
								<b style={{ color: 'black' }}>= {getFormattedCurrency(data.value)}</b>
							</div>
						)
					}}
				/>
			</div>
		)
	}

	const renderPaymentCountChart = () => {
		const totalOrderCount = salesAnalyticsPaymentStatistics.count.total
		const data = [
			{
				'type': 'Payment Due',
				'value': salesAnalyticsPaymentStatistics.count.due,
				'color': 'hsl(0, 100%, 74%)'
			},
			{
				'type': 'Payment Collected',
				'value': salesAnalyticsPaymentStatistics.count.collected,
				'color': 'hsl(169, 80%, 52%)'
			}
		]
		return (
			<div className={styles.graphContainerOrderCount}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
						Total Order Count: {totalOrderCount}
					</h3>
				</div>
				<ResponsiveBar
					colors={({ data }) => data['color']}
					data={data}
					indexBy='type'
					margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
					padding={0.3}
					axisTop={null}
					axisRight={null}
					enableLabel={false}
					tooltip={({ data }) => {
						return (
							<div className={styles.tooltipPayment}>
								<span style={{ background: data.color, height: 12, width:12 }} />
								<span>{data.type} : {data.value}</span>
							</div>
						)
					}}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendPosition: 'middle',
						legendOffset: 32
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 1,
						tickRotation: 0,
						legend: 'Count',
						legendPosition: 'middle',
						legendOffset: -55
					}}
					theme={{
						axis: {
							legend: {
								text: {
									fill: '#64748B'
								}
							},
							fontSize: '12px'
						}
					}}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
					role='application'
				/>
			</div>
		)
	}

	const renderPaymentAmountChart = () => {
		const totalOrderAmount = salesAnalyticsPaymentStatistics.amount.total
		const data = [
			{
				'type': 'Payment Due',
				'value': salesAnalyticsPaymentStatistics.amount.due,
				'color': 'hsl(0, 100%, 74%)'
			},
			{
				'type': 'Payment Collected',
				'value': salesAnalyticsPaymentStatistics.amount.collected,
				'color': 'hsl(169, 80%, 52%)'
			}
		]
		return (
			<div className={styles.graphContainerOrderAmount}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<h3 style={{ marginBottom: 0 }} className={styles.subTitle}>
						Total Sales Value: {getFormattedCurrency(totalOrderAmount, true)}
					</h3>
				</div>
				<ResponsiveBar
					colors={({ data }) => data['color']}
					data={data}
					indexBy='type'
					margin={{ top: 50, right: 20, bottom: 50, left: 60 }}
					padding={0.3}
					axisTop={null}
					axisRight={null}
					enableLabel={false}
					tooltip={({ data }) => {
						return (
							<div className={styles.tooltipPayment}>
								<span style={{ background: data.color, height: 12, width:12 }} />
								<span>{data.type} : {data.value}</span>
							</div>
						)
					}}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendPosition: 'middle',
						legendOffset: 32
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 1,
						tickRotation: 0,
						legend: getCurrencyCode(),
						legendPosition: 'middle',
						legendOffset: -55,
						format: '.2s'
					}}
					theme={{
						axis: {
							legend: {
								text: {
									fill: '#64748B'
								}
							},
							fontSize: '12px'
						}
					}}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
					role='application'
				/>
			</div>
		)
	}

	const renderOrderStatusFilter = () => {
		return (
			<>
				<div className={styles.filterOptions}>
					<h4>Order Statuses</h4>
					<div>
						<Checkbox
							checked={isAllOrderStatusesChecked(orderStatusFilter)}
							value='isAllChecked'
							defaultChecked={false}
							onChange={(e) => {
								if (e.target.checked) {
									return setOrderStatusFilter(getOrderStatusFilters(true))
								}
								setOrderStatusFilter(getOrderStatusFilters(false))
							}}
						>
							Select All
						</Checkbox>
					</div>
					{
						orderStatusOptions.map(orderStatus => {
							return (
								<div key={orderStatus.value}>
									<Checkbox
										checked={orderStatusFilter[orderStatus.value]}
										value={orderStatus.value}
										onChange={(e) => setOrderStatusFilter({
											...orderStatusFilter, [orderStatus.value]: e.target.checked
										})}
									>
										{orderStatus.label}
									</Checkbox>
								</div>
							)
						})
					}
				</div>
			</>
		)
	}

	const renderOrderSourceStatusFilter = () => {
		return (
			<>
				<div className={styles.filterOptions}>
					<h4>Order Statuses</h4>
					<div>
						<Checkbox
							checked={isAllOrderStatusesChecked(orderSourceStatusFilter)}
							value='isAllChecked'
							defaultChecked={false}
							onChange={(e) => {
								if (e.target.checked) {
									return setOrderSourceStatusFilter(getOrderStatusFilters(true))
								}
								setOrderSourceStatusFilter(getOrderStatusFilters(false))
							}}
						>
							Select All
						</Checkbox>
					</div>
					{
						orderStatusOptions.map(orderStatus => {
							return (
								<div key={orderStatus.value}>
									<Checkbox
										checked={orderSourceStatusFilter[orderStatus.value]}
										value={orderStatus.value}
										onChange={(e) => setOrderSourceStatusFilter({
											...orderSourceStatusFilter, [orderStatus.value]: e.target.checked
										})}
									>
										{orderStatus.label}
									</Checkbox>
								</div>
							)
						})
					}
				</div>
			</>
		)
	}

	const renderOrdersStat = () => {
		return (
			<>
				<div className={styles.statContainer}>
					<div style={{ position: 'relative' }}>
						<div style={{ display: 'flex', marginBottom: 12, alignItems: 'center' }}>
							<h3 style={{ marginBottom: 0 }}>Orders</h3>
							<Button
								style={{ marginLeft: 12, border: 'none' }}
								icon={<ReloadOutlined />}
								onClick={() => getGeneralAnalyticsStatistics(analyticsTypes.orders)}
								loading={isLoadingGeneralAnalyticsStatistics}
							/>
							<div style={{ marginLeft: 'auto', display: 'flex', gap: 16 }}>
								{
									statPeriodDateRange.timePeriod.key === timeSpan.custom &&
									<div style={{ display: 'flex', marginRight: 12 }}>
										{renderDatePicker(statPeriodDateRange, setStatPeriodDateRange)}
									</div>
								}
								<Popover
									trigger='click'
									placement='bottomLeft'
									visible={isOrderStatusFilterVisible}
									onVisibleChange={setIsOrderStatusFilterVisible}
									content={renderOrderStatusFilter()}
								>
									<div
										className={styles.timeTabSelected}
									>
										Order Status
										<DownOutlined style={{ marginLeft: 12 }} />
									</div>
								</Popover>
								<Dropdown
									overlayClassName={styles.overlay}
									trigger={['click']}
									overlay={() => {
										return (
											<Menu>
												{
													timePeriods.map(orderPeriod => {
														return (
															<Menu.Item
																key={orderPeriod.key}
																onClick={() => {
																	onTimePeriodSelected(orderPeriod, setStatPeriodDateRange)
																	setShowMasterFilterSelected(false)
																}}
															>
																{orderPeriod.label}
															</Menu.Item>
														)
													})
												}
											</Menu>
										)
									}}
								>
									<div
										className={styles.timeTabSelected}
									>
										{statPeriodDateRange.timePeriod.label}
										<DownOutlined style={{ marginLeft: 12 }} />
									</div>
								</Dropdown>
							</div>
						</div>
						{renderDateRange(statPeriodDateRange)}
						<Row gutter={30}>
							<Col flex={1} span={12} className={styles.statColumnLeft}>
								<h3 className={styles.subTitle}>
									Order Count
									<Tooltip title='Order count based on the selected time period and the order statuses.' placement='top'>
										<img src='/img/info.svg' alt='Alert icon' />
									</Tooltip>
								</h3>
								<div style={{ display: 'flex', flex: 1 }}>
									<div className={styles.statLeft}>
										<h2>Total</h2>
										<div className={styles.statValueContainer}>
											<div style={{ marginRight: 12 }}>{generalAnalyticsStatistics.orders.total.value}</div>
										</div>
									</div>
								</div>
							</Col>
							<Col flex={1} span={12} className={styles.statColumnRight}>
								<h3 className={styles.subTitle}>
									Sales Amount
									<Tooltip title='Sales amount based on the selected time period and the order statuses.' placement='top'>
										<img src='/img/info.svg' alt='Alert icon' />
									</Tooltip>
								</h3>
								<div style={{ display: 'flex', flex: 1 }}>
									<div className={styles.statRight}>
										<h2>Total</h2>
										<div className={styles.statValueContainer}>
											<div style={{ marginRight: 12 }}>{getFormattedCurrency(generalAnalyticsStatistics.sales.total.value)}</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
						{
							(generalAnalyticsStatistics?.ordersByStatus?.length > 0 || generalAnalyticsStatistics?.ordersBySubStatus?.length > 0) &&
							<div className={styles.expandButtonContainer}>
								<Button
									icon={!expanded ? <DownOutlined /> : <UpOutlined />}
									onClick={() => setExpanded(!expanded)}
								/>
							</div>
						}
						{
							expanded &&
							<div className={styles.orderCards}>
								{
									generalAnalyticsStatistics?.ordersByStatus.map((item, index) => {
										if (item.status === orderStatuses.SALES_ORDER_FLAGGED_STATUS.toUpperCase() || item.status === orderStatuses.SALES_ORDER_COMPLETED_STATUS.toUpperCase()) {
											return
										}

										return (
											<div
												key={index}
												className={styles.statusStatContainer}
												onClick={() => applyFilterAndRedirect(item.status)}
											>
												<p style={{ color: statBystatusColor[item.status].color, height: '12px' }}>{statBystatusColor[item.status].label}</p>
												<p className={styles.statByStatusValueContainer}>{item.count}</p>
												<p className={styles.statByStatusValueContainer}>{`${getFormattedCurrency(item.total)}`}</p>
											</div>
										)
									})
								}
								{
									generalAnalyticsStatistics?.ordersBySubStatus.map((item, index) => {
										return (
											statBystatusColor[item.subStatus] ?
												<div
													key={index}
													className={styles.statusStatContainer}
													onClick={() => applyFilterAndRedirect(getOrderStatus(item.subStatus), item.subStatus)}
												>
													<p style={{ color: statBystatusColor[item.subStatus]?.color, height: '12px' }}>{statBystatusColor[item.subStatus]?.label}</p>
													<p className={styles.statByStatusValueContainer}>{item.count}</p>
													<p className={styles.statByStatusValueContainer}>{`${getFormattedCurrency(item.total)}`}</p>
												</div> : null
										)
									})
								}
							</div>
						}
					</div>
				</div>
			</>
		)
	}

	const renderDateRange = (timeRange) => {
		return (
			<div className={styles.timeScheduleContainer}>
				{timeRange.timePeriod.key === timeSpan.lifetime && <span>Lifetime</span>}
				{(timeRange.timePeriod.key === timeSpan.today ||
                timeRange.timePeriod.key === timeSpan.yesterday ||
                timeRange.timePeriod.key === timeSpan.custom) &&
					<div>{moment(timeRange.fromDate).format(dateFormatStringWithTime)} - {moment(timeRange.toDate).format(dateFormatStringWithTime)}</div>}
				{(timeRange.timePeriod.key === timeSpan.currentWeek ||
                timeRange.timePeriod.key === timeSpan.week ||
                timeRange.timePeriod.key === timeSpan.currentMonth ||
                timeRange.timePeriod.key === timeSpan.month ||
                timeRange.timePeriod.key === timeSpan.year) &&
					<div>{moment(timeRange.fromDate).format(dateFormatString)} - {moment(timeRange.toDate).format(dateFormatString)}</div>}
			</div>
		)
	}

	const renderDatePicker = (dateRange, setDateRange) => {
		return (
			<Space size='small'>
				<DatePicker
					dropdownClassName={styles.overlay}
					placeholder='From'
					value={dateRange.fromDate ? moment(dateRange.fromDate) : null}
					onChange={(date) => dateRangeChangeHandler(setDateRange, 'fromDate', date?.toDate())}
					showTime={{
						use12Hours: true
					}}
					format='YYYY-MM-DD h:mm A'
				/>
				<div className={styles.dash} />
				<DatePicker
					dropdownClassName={styles.overlay}
					placeholder='To'
					value={dateRange.toDate ? moment(dateRange.toDate) : null}
					onChange={(date) => dateRangeChangeHandler(setDateRange, 'toDate', date?.toDate())}
					disabledDate={current => current && current.valueOf() < moment(dateRange.fromDate)}
					showTime={{
						use12Hours: true
					}}
					format='YYYY-MM-DD h:mm A'
				/>
			</Space>
		)
	}

	const renderMasterFilter = () => {
		return (
			<>
				<span className={styles.masterFilterText}>Master Filter</span>
				<Dropdown
					overlayClassName={styles.overlay}
					trigger={['click']}
					overlay={() => {
						return (
							<Menu>
								<Menu.Item
									key='isAllChecked'
								>
									<Checkbox
										checked={selectedLocationIds?.length === locations.length || selectedLocationIds === undefined}
										value='isAllChecked'
										style={{ width: '100%' }}
										onChange={(e) => {
											if (e.target.checked) {
												setSelectedLocationIds(locations.map(location => location.id))
											} else {
												setSelectedLocationIds([])
											}
										}}
									>
                                        Select All
									</Checkbox>
								</Menu.Item>
								{
									locations.map(location => {
										return (
											<Menu.Item
												key={location.id}
											>
												<Checkbox
													checked={selectedLocationIds?.includes(location.id) || selectedLocationIds === undefined}
													style={{ width: '100%' }}
													value={location.id}
													onChange={(e) => {
														if (e.target.checked) {
															const ids = selectedLocationIds ? selectedLocationIds : []
															setSelectedLocationIds([...ids, location.id])
														} else {
															let ids = selectedLocationIds ? selectedLocationIds : []
															if (selectedLocationIds === undefined) {
																ids = locations.map(location => location.id)
															}
															setSelectedLocationIds(ids.filter(id => id !== location.id))
														}
													}}
												>
													{location.label || location.address}
												</Checkbox>
											</Menu.Item>
										)
									})
								}
							</Menu>
						)
					}}
				>
					<div className={styles.timeTabSelected} style={{ borderRight: 'none' }}>
						Warehouse
						<DownOutlined style={{ marginLeft: 12 }} />
					</div>
				</Dropdown>
				<Dropdown
					overlayClassName={styles.overlay}
					trigger={['click']}
					overlay={() => {
						return (
							<Menu>
								{timePeriods.map(orderPeriod => {
									return (
										<Menu.Item key={orderPeriod.key}
											onClick={ () => {
												onTimePeriodSelected(
													orderPeriod,
													setMasterFilterDateRange
												)
												setShowMasterFilterSelected(true)
											}}
										>
											{orderPeriod.label}
										</Menu.Item>
									)
								})}
							</Menu>
						)
					}}
				>
					<div className={styles.timeTabSelected}>
						{masterFilterDateRange?.timePeriod?.label}
						<DownOutlined style={{ marginLeft: 12 }} />
					</div>
				</Dropdown>
			</>

		)
	}

	const renderDashboardData = () => {
		return (
			<div className={styles.content}>
				<div className={styles.data}>
					<div className={styles.topContainer}>
						<div>
							<div className={styles.pageTitle}>Dashboard</div>
							{showMasterFilterSelected &&
								<div className={styles.masterFilterDateContainer}>
									<span className={styles.timeScheduleContainer}><b>{masterFilterDateRange.timePeriod.label}:</b> &nbsp;</span>
									{renderDateRange(masterFilterDateRange)}
									<span className={styles.timeScheduleContainer}>&nbsp; filter is selected and applied to all over the Dashboard</span>
								</div>}
						</div>
						<div className={styles.masterFilter}>
							{masterFilterDateRange.timePeriod.key === timeSpan.custom && renderDatePicker(masterFilterDateRange, setMasterFilterDateRange)}
							{renderMasterFilter()}
						</div>
					</div>

					<div style={{ display: 'flex', width: '100%' }}>
						<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
							{renderOrdersStat()}
						</div>
					</div>
					<div style={{ marginTop: 24 }}>
						<div className={styles.statContainer}>
							<div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
								<h2 style={{ marginBottom: 0 }}>Trends</h2>
								<Button
									style={{ marginLeft: 12, border: 'none' }}
									icon={<ReloadOutlined />}
									onClick={() => getSalesAnalyticsOrderTrends()}
									loading={isLoadingSalesAnalyticsOrderTrends}
								/>
								<div style={{ marginLeft: 'auto', display: 'flex', gap: 16 }}>
									<Dropdown
										overlayClassName={styles.overlay}
										trigger={['click']}
										overlay={() => {
											return (
												<Menu>
													{
														transitionStatuses.map(status => {
															return (
																<Menu.Item
																	key={status.key}
																	onClick={() => {
																		setGeneralOrderCountStatusFilter(status)
																	}}
																>
																	{status.label}
																</Menu.Item>
															)
														})
													}
												</Menu>
											)
										}}
									>
										<div
											className={styles.timeTabSelected}
										>
											{generalOrderCountStatusFilter.label}
											<DownOutlined style={{ marginLeft: 12 }} />
										</div>
									</Dropdown>
									<Dropdown
										overlayClassName={styles.overlay}
										trigger={['click']}
										overlay={() => {
											return (
												<Menu>
													{
														timeDifferentiateTabs.map(tabItem => {
															return (
																<Menu.Item
																	key={tabItem.key}
																	onClick={() => {
																		setGeneralOrderCountCurrentTimeTab(tabItem.value)
																	}}
																>
																	{tabItem.title}
																</Menu.Item>
															)
														})
													}
												</Menu>
											)
										}}
									>
										<div
											className={styles.timeTabSelected}
										>
											{timeDifferentiateTabs.find(tab => tab.value === generalOrderCountCurrentTimeTab)?.title}
											<DownOutlined style={{ marginLeft: 12 }} />
										</div>
									</Dropdown>
								</div>
							</div>
							<div style={{ display: 'flex' }}>
								<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
									<Row gutter={30}>
										<Col flex={1} span={12} className={styles.statColumnLeft}>
											{renderSalesCountChart()}
										</Col>
										<Col flex={1} span={12} className={styles.statColumnRight}>
											{renderSalesAmountChart()}
										</Col>
									</Row>
								</div>
							</div>
						</div>
						<div className={styles.statContainer} style={{ marginTop: 24 }}>
							<div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
								<h2 style={{ marginBottom: 0 }}>Average Basket Analysis</h2>
								<Button
									style={{ marginLeft: 12, border: 'none' }}
									icon={<ReloadOutlined />}
									onClick={() => getSalesAnalyticsAverageBasketTrends()}
									loading={isLoadingSalesAnalyticsAverageBasketTrends}
								/>
								<div style={{ marginLeft: 'auto', display: 'flex', gap: 16 }}>
									<Dropdown
										overlayClassName={styles.overlay}
										trigger={['click']}
										overlay={() => {
											return (
												<Menu>
													{
														transitionStatuses.map(status => {
															return (
																<Menu.Item
																	key={status.key}
																	onClick={() => setAverageBasketStatus(status)}
																>
																	{status.label}
																</Menu.Item>
															)
														})
													}
												</Menu>
											)
										}}
									>
										<div
											className={styles.timeTabSelected}
										>
											{averageBasketStatus.label}
											<DownOutlined style={{ marginLeft: 12 }} />
										</div>
									</Dropdown>
									<Dropdown
										overlayClassName={styles.overlay}
										trigger={['click']}
										overlay={() => {
											return (
												<Menu>
													{
														timeDifferentiateTabs.map(tabItem => {
															return (
																<Menu.Item
																	key={tabItem.key}
																	onClick={() => {
																		setGeneralAverageBasketCurrentTimeTab(tabItem.value)
																	}}
																>
																	{tabItem.title}
																</Menu.Item>
															)
														})
													}
												</Menu>
											)
										}}
									>
										<div
											className={styles.timeTabSelected}
										>
											{timeDifferentiateTabs.find(tab => tab.value === generalAverageBasketCurrentTimeTab)?.title}
											<DownOutlined style={{ marginLeft: 12 }} />
										</div>
									</Dropdown>
								</div>
							</div>
							<div style={{ display: 'flex' }}>
								<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
									<Row gutter={30}>
										<Col flex={1} span={12} className={styles.statColumnLeft}>
											{renderAverageBasketUnitChart()}
										</Col>
										<Col flex={1} span={12} className={styles.statColumnRight}>
											{renderAverageBasketValueChart()}
										</Col>
									</Row>
								</div>
							</div>
						</div>
						<div className={styles.statContainer} style={{ marginTop: 24 }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<Row gutter={30}>
									<Col flex={1} span={12} className={styles.statColumnLeft}>
										{renderOrderRatePercentageChart()}
									</Col>
									<Col flex={1} span={12} className={styles.statColumnRight}>
										{renderOrderRateCycleTimeBarChart()}
									</Col>
								</Row>
							</div>
						</div>
						<div className={styles.statContainer} style={{ marginTop: 24 }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<h2 style={{ marginBottom: 0 }} className={styles.subTitle}>
										Order Source
										<Tooltip title='Order Count and Order Value based on the Order Source of the selected order statuses in a time period.' placement='top'>
											<img src='/img/info.svg' alt='Alert icon' />
										</Tooltip>
									</h2>
									<Button
										style={{ marginLeft: 12, border: 'none' }}
										icon={<ReloadOutlined />}
										onClick={() => getGeneralAnalyticsOrderSource()}
										loading={isLoadingOrderSource}
									/>
									<div style={{ marginLeft: 'auto', display: 'flex', gap: 16 }}>
										{orderSourceDateRange.timePeriod.key === timeSpan.custom && renderDatePicker(orderSourceDateRange, setOrderSourceDateRange)}
										<div className={styles.productViewButton} style={{ width: 32, height: 32 }}>
											{
												isDownloadingProducts ?
													<Spin /> :
													<img
														src='/img/download-green.svg'
														style={{
															cursor: !permissions.exportAnalytics ? 'not-allowed' : 'pointer',
															filter: !permissions.exportAnalytics ? 'invert(89%) sepia(0%) saturate(0%) hue-rotate(184deg) brightness(88%) contrast(104%)' : undefined
														}}
														onClick={() => downloadOrderSourcesCsv()}
													/>
											}
										</div>
										<Popover
											trigger='click'
											placement='bottomLeft'
											visible={isOrderSourceStatusFilterVisible}
											onVisibleChange={setIsOrderSourceStatusFilterVisible}
											content={renderOrderSourceStatusFilter()}
										>
											<div
												className={styles.timeTabSelected}
											>
												Order Status
												<DownOutlined style={{ marginLeft: 12 }} />
											</div>
										</Popover>
										<Dropdown
											overlayClassName={styles.overlay}
											trigger={['click']}
											overlay={() => {
												return (
													<Menu>
														{
															timePeriods.map(orderPeriod => {
																return (
																	<Menu.Item
																		key={orderPeriod.key}
																		onClick={() => {
																			onTimePeriodSelected(orderPeriod, setOrderSourceDateRange)
																			setShowMasterFilterSelected(false)
																		}}
																	>
																		{orderPeriod.label}
																	</Menu.Item>
																)
															})
														}
													</Menu>
												)
											}}
										>
											<div
												className={styles.timeTabSelected}
											>
												{orderSourceDateRange.timePeriod.label}
												<DownOutlined style={{ marginLeft: 12 }} />
											</div>
										</Dropdown>
									</div>
								</div>
								{renderDateRange(orderSourceDateRange)}
								<Row gutter={30}>
									<Col flex={1} span={12} className={styles.statColumnLeft}>
										{renderOrderSourceChart()}
									</Col>
									<Col flex={1} span={12} className={styles.statColumnRight}>
										{renderOrderSourceRevenueBarChart()}
									</Col>
								</Row>
							</div>
						</div>
						<div className={styles.statContainer} style={{ marginTop: 24 }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<h2 style={{ marginBottom: 0 }} className={styles.subTitle}>
                                        Cancelled & Returned Orders
									</h2>
									<Button
										style={{ marginLeft: 12, border: 'none' }}
										icon={<ReloadOutlined />}
										onClick={() => getGeneralAnalyticsCancelledOrderReason()}
										loading={isLoadingCancelledOrderReason}
									/>
									<div style={{ marginLeft: 'auto', display: 'flex', gap: 16 }}>
										{cancelledOrderReasonDateRange.timePeriod.key === timeSpan.custom && renderDatePicker(cancelledOrderReasonDateRange, setCancelledOrderReasonDateRange)}
										<Dropdown
											trigger={['click']}
											overlay={() => {
												return (
													<Menu>
														{
															timePeriods.map(orderPeriod => {
																return (
																	<Menu.Item
																		key={orderPeriod.key}
																		onClick={() => {
																			onTimePeriodSelected(
																				orderPeriod,
																				setCancelledOrderReasonDateRange
																			)
																			setShowMasterFilterSelected(false)
																		}}
																	>
																		{orderPeriod.label}
																	</Menu.Item>
																)
															})
														}
													</Menu>
												)
											}}
										>
											<div
												className={styles.timeTabSelected}
											>
									            {cancelledOrderReasonDateRange.timePeriod.label}
												<DownOutlined style={{ marginLeft: 12 }} />
											</div>
										</Dropdown>
									</div>
								</div>
								<div className={styles.dateRangeAndDatePickerContainer}>
									{renderDateRange(cancelledOrderReasonDateRange)}
								</div>
								<Row gutter={30}>
									<Col flex={1} span={12} className={styles.statColumnLeft}>
										{renderCancelledOrderReasonChart()}
									</Col>
									<Col flex={1} span={12} className={styles.statColumnRight}>
										{renderReturnedOrderReasonChart()}
									</Col>
								</Row>
							</div>
						</div>
						<TopSalesOrderLocations masterFilterOption={salesOrderLocation} selectedLocationIds={selectedLocationIds} />
						<div className={styles.statContainer} style={{ marginTop: 24 }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<h2 style={{ marginBottom: 0 }} className={styles.subTitle}>
                                        Delivery Partners&apos; Orders
										<Tooltip title='Delivery Partners’ Order count and value based on the selected order statuses in a time period.' placement='top'>
											<img src='/img/info.svg' alt='Alert icon' />
										</Tooltip>
									</h2>
									<Button
										style={{ marginLeft: 12, border: 'none' }}
										icon={<ReloadOutlined />}
										onClick={() => getECommerceDeliveryPartnerAnalyticsOrderRate()}
										loading={isLoadingDeliveryPartnersOrderRate}
									/>
									<div style={{ marginLeft: 'auto', display: 'flex', gap: 16 }}>
										{deliveryPartnersOrderRateDateRange.timePeriod.key === timeSpan.custom && renderDatePicker(deliveryPartnersOrderRateDateRange, setDeliveryPartnersOrderRateDateRange)}
										<Dropdown
											overlayClassName={styles.overlay}
											trigger={['click']}
											overlay={() => {
												return (
													<Menu>
														{
															transitionStatuses.map(status => {
																return (
																	<Menu.Item
																		key={status.key}
																		onClick={() => {
																			setDeliveryPartnerOrderStatusFilter(status)
																		}}
																	>
																		{status.label}
																	</Menu.Item>
																)
															})
														}
													</Menu>
												)
											}}
										>
											<div
												className={styles.timeTabSelected}
											>
												{deliveryPartnerOrderStatusFilter.label}
												<DownOutlined style={{ marginLeft: 12 }} />
											</div>
										</Dropdown>
										<Dropdown
											trigger={['click']}
											overlay={() => {
												return (
													<Menu>
														{
															timePeriods.map(orderPeriod => {
																return (
																	<Menu.Item
																		key={orderPeriod.key}
																		onClick={() => {
																			onTimePeriodSelected(orderPeriod, setDeliveryPartnersOrderRateDateRange)
																			setShowMasterFilterSelected(false)
																		}}
																	>
																		{orderPeriod.label}
																	</Menu.Item>
																)
															})
														}
													</Menu>
												)
											}}
										>
											<div
												className={styles.timeTabSelected}
											>
												{deliveryPartnersOrderRateDateRange.timePeriod.label}
												<DownOutlined style={{ marginLeft: 12 }} />
											</div>
										</Dropdown>
									</div>
								</div>
								<div className={styles.dateRangeAndDatePickerContainer}>
									{renderDateRange(deliveryPartnersOrderRateDateRange)}
								</div>
								<Row gutter={30}>
									<Col flex={1} span={12} className={styles.statColumnLeft}>
										{renderDeliveryPartnersOrderRateChart()}
									</Col>
									<Col flex={1} span={12} className={styles.statColumnRight}>
										{renderDeliveryPartnersSalesValueChart()}
									</Col>
								</Row>
							</div>
						</div>
					</div>
					<div className={styles.statContainer} style={{ marginTop: 24 }}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<h2 style={{ marginBottom: 0 }} className={styles.subTitle}>
								Payments
								<Tooltip title='Payments status of delivered orders based on a time period' placement='top'>
									<img src='/img/info.svg' alt='Alert icon' />
								</Tooltip>
							</h2>
							<Button
								style={{ marginLeft: 12, border: 'none' }}
								icon={<ReloadOutlined />}
								onClick={() => getSalesAnalyticsPaymentStatistics()}
								loading={isLoadingSalesAnalyticsPaymentStatistics}
							/>
							<div style={{ marginLeft: 'auto', display: 'flex', gap: 16 }}>
								{
									paymentStatDateRange.timePeriod.key === timeSpan.custom &&
									<div style={{ display: 'flex', marginRight: 12 }}>
										{renderDatePicker(paymentStatDateRange, setPaymentStatDateRange)}
									</div>
								}
								<Dropdown
									overlayClassName={styles.overlay}
									trigger={['click']}
									overlay={() => {
										return (
											<Menu>
												{
													timePeriods.map(orderPeriod => {
														return (
															<Menu.Item
																key={orderPeriod.key}
																onClick={() => {
																	onTimePeriodSelected(
																		orderPeriod,
																		setPaymentStatDateRange
																	)
																	setShowMasterFilterSelected(false)
																}}
															>
																{orderPeriod.label}
															</Menu.Item>
														)
													})
												}
											</Menu>
										)
									}}
								>
									<div
										className={styles.timeTabSelected}
									>
										{paymentStatDateRange.timePeriod.label}
										<DownOutlined style={{ marginLeft: 12 }} />
									</div>
								</Dropdown>
							</div>
						</div>
						{renderDateRange(paymentStatDateRange)}
						{
							salesAnalyticsPaymentStatistics &&
							<div style={{ display: 'flex' }}>
								<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
									<Row gutter={30}>
										<Col flex={1} span={12} className={styles.statColumnLeft}>
											{renderPaymentCountChart()}
										</Col>
										<Col flex={1} span={12} className={styles.statColumnRight}>
											{renderPaymentAmountChart()}
										</Col>
									</Row>
								</div>
							</div>
						}
					</div>
					<div className={styles.statContainer} style={{ marginTop: 24 }}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<h2 style={{ marginBottom: 0 }} className={styles.subTitle}>
								Discount Allowed & Advanced Payments
								<Tooltip title='The number of orders with discounts and advanced payments, and their values in the time period' placement='top'>
									<img src='/img/info.svg' alt='Alert icon' />
								</Tooltip>
							</h2>
							<Button
								style={{ marginLeft: 12, border: 'none' }}
								icon={<ReloadOutlined />}
								onClick={() => getGeneralAnalyticsDiscountAndAdvancePaymentOrderStats()}
								loading={isLoadingDiscountAndAdvancePaymentOrderStats}
							/>
							<div style={{ marginLeft: 'auto', display: 'flex', gap: 16 }}>
								{discountAndAdvancePaymentOrderStatsDateRange.timePeriod.key === timeSpan.custom && renderDatePicker(discountAndAdvancePaymentOrderStatsDateRange, setDiscountAndAdvancePaymentOrderStatsDateRange)}
								<Dropdown
									overlayClassName={styles.overlay}
									trigger={['click']}
									overlay={() => {
										return (
											<Menu>
												{
													timePeriods.map(orderPeriod => {
														return (
															<Menu.Item
																key={orderPeriod.key}
																onClick={() => {
																	onTimePeriodSelected(
																		orderPeriod,
																		setDiscountAndAdvancePaymentOrderStatsDateRange
																	)
																	setShowMasterFilterSelected(false)
																}}
															>
																{orderPeriod.label}
															</Menu.Item>
														)
													})
												}
											</Menu>
										)
									}}
								>
									<div
										className={styles.timeTabSelected}
									>
										{discountAndAdvancePaymentOrderStatsDateRange.timePeriod.label}
										<DownOutlined style={{ marginLeft: 12 }} />
									</div>
								</Dropdown>
							</div>
						</div>
						{renderDateRange(discountAndAdvancePaymentOrderStatsDateRange)}
						<div style={{ display: 'flex' }}>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<Row gutter={30}>
									<Col flex={1} span={12} className={styles.statColumnLeft}>
										{renderDiscountAndAdvancePaymentOrderCountChart()}
									</Col>
									<Col flex={1} span={12} className={styles.statColumnRight}>
										{renderDiscountAndAdvancePaymentOrderValueChart()}
									</Col>
								</Row>
							</div>
						</div>
					</div>
					<div className={styles.statContainer} style={{ marginTop: 24 }}>
						<div style={{ display: 'flex' }}>
							<div>
								<div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
									<h2 style={{ alignSelf: 'center', marginBottom: '0px' }} className={styles.subTitle}>
										Top 10 Selling Items
										<Tooltip title='Top 10 selling products or variants and categorized by Requested, Approved, In-Transit, and Delivered orders based on a time period' placement='top'>
											<img src='/img/info.svg' alt='Alert icon' />
										</Tooltip>
									</h2>
									<Button
										style={{ border: 'none' }}
										icon={<ReloadOutlined />}
										onClick={getTopSellingProducts}
										loading={isLoadingProducts}
									/>
								</div>
							</div>
							<div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 16 }}>
								{
									topProductsDateRange.timePeriod.key === timeSpan.custom &&
									<div style={{ display: 'flex', marginRight: 12 }}>
										{renderDatePicker(topProductsDateRange, setTopProductsDateRange)}
									</div>
								}
								<Dropdown
									overlayClassName={styles.overlay}
									trigger={['click']}
									overlay={() => {
										return (
											<Menu>
												{
													productTypes.map(type => {
														return (
															<Menu.Item
																key={type.key}
																onClick={() => setTopProductsType(type)}
															>
																{type.label}
															</Menu.Item>
														)
													})
												}
											</Menu>
										)
									}}
								>
									<div
										className={styles.timeTabSelected}
									>
										{topProductsType.label}
										<DownOutlined style={{ marginLeft: 12 }} />
									</div>
								</Dropdown>
								<Dropdown
									overlayClassName={styles.overlay}
									trigger={['click']}
									overlay={() => {
										return (
											<Menu>
												{
													transitionStatuses.map(status => {
														return (
															<Menu.Item
																key={status.key}
																onClick={() => setTopProductsStatus(status)}
															>
																{status.label}
															</Menu.Item>
														)
													})
												}
											</Menu>
										)
									}}
								>
									<div
										className={styles.timeTabSelected}
									>
										{topProductsStatus.label}
										<DownOutlined style={{ marginLeft: 12 }} />
									</div>
								</Dropdown>
								<Dropdown
									overlayClassName={styles.overlay}
									trigger={['click']}
									overlay={() => {
										return (
											<Menu>
												{
													timePeriods.map(orderPeriod => {
														return (
															<Menu.Item
																key={orderPeriod.key}
																onClick={() => {
																	onTimePeriodSelected(
																		orderPeriod,
																		setTopProductsDateRange
																	)
																	setShowMasterFilterSelected(false)
																}}
															>
																{orderPeriod.label}
															</Menu.Item>
														)
													})
												}
											</Menu>
										)
									}}
								>
									<div
										className={styles.timeTabSelected}
									>
										{topProductsDateRange.timePeriod.label}
										<DownOutlined style={{ marginLeft: 12 }} />
									</div>
								</Dropdown>
								<div className={styles.productViewButton} style={{ width: 32, height: 32 }}>
									{
										isDownloadingProducts ?
											<Spin /> :
											<img
												src='/img/download-green.svg'
												style={{
													cursor: !permissions.exportAnalytics ? 'not-allowed' : 'pointer',
													filter: !permissions.exportAnalytics ? 'invert(89%) sepia(0%) saturate(0%) hue-rotate(184deg) brightness(88%) contrast(104%)' : undefined
												}}
												onClick={() => downloadTopSellingProductsCsv()}
											/>
									}
								</div>
								<img
									src={showGraphView ? '/img/image-view.svg' : '/img/graph-view.svg' }
									className={styles.productViewButton}
									onClick={() => {
										const showGraph = !showGraphView
										setShowGraphView(showGraph)
										if (showGraph) {
											getTopSellingProducts()
										}
									}}
								/>
							</div>

						</div>
						<div style={{ marginBottom: 24 }}>
							{renderDateRange(topProductsDateRange)}
						</div>

						{renderTopProducts()}
					</div>
					<div className={styles.statContainer} style={{ marginTop: 24 }}>
						<div style={{ display: 'flex' }}>
							<div className={styles.topTenCustomerContainerHead}>
								<div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
									<h2 style={{ alignSelf: 'center', marginBottom: 0 }} className={styles.subTitle}>
										Top 10 Customers
										<Tooltip title='Top 10 customers based on the selected order status and by order frequency or by order value based on the selected time period.' placement='top'>
											<img src='/img/info.svg' alt='Alert icon' />
										</Tooltip>
									</h2>
									<Button
										style={{ border: 'none' }}
										icon={<ReloadOutlined />}
										onClick={getTopCustomers}
										loading={isTopCustomerLoading}
									/>
								</div>
								<div className={styles.topTenCustomerBtnContainer}>
									<Dropdown
										overlayClassName={styles.overlay}
										trigger={['click']}
										overlay={() => {
											return (
												<Menu>
													{
														transitionStatuses.map(status => {
															return (
																<Menu.Item
																	key={status.key}
																	onClick={() => {
																		setCustomerStatusFilter(status)
																	}}
																>
																	{status.label}
																</Menu.Item>
															)
														})
													}
												</Menu>
											)
										}}
									>
										<div
											className={styles.timeTabSelected}
										>
											{customerStatusFilter.label}
											<DownOutlined style={{ marginLeft: 12 }} />
										</div>
									</Dropdown>
									<Dropdown
										overlayClassName={styles.overlay}
										trigger={['click']}
										overlay={() => {
											return (
												<Menu>
													{
														customerFilters.map(customerFilter => {
															return (
																<Menu.Item
																	key={customerFilter.key}
																	onClick={() => setCustomerFilter(customerFilter)}
																>
																	{customerFilter.label}
																</Menu.Item>
															)
														})
													}
												</Menu>
											)
										}}
									>
										<div
											className={styles.timeTabSelected}
										>
											{customerFilter.label}
											<DownOutlined style={{ marginLeft: 12 }} />
										</div>
									</Dropdown>
									{customerDateRange.timePeriod.key === timeSpan.custom && renderDatePicker(customerDateRange, setCustomerDateRange)}
									<Dropdown
										overlayClassName={styles.overlay}
										trigger={['click']}
										overlay={() => {
											return (
												<Menu>
													{
														timePeriods.map(orderPeriod => {
															return (
																<Menu.Item
																	key={orderPeriod.key}
																	onClick={() => {
																		onTimePeriodSelected(
																			orderPeriod,
																			setCustomerDateRange
																		)
																		setShowMasterFilterSelected(false)
																	}}
																>
																	{orderPeriod.label}
																</Menu.Item>
															)
														})
													}
												</Menu>
											)
										}}
									>
										<div
											className={styles.timeTabSelected}
										>
											{customerDateRange.timePeriod.label}
											<DownOutlined style={{ marginLeft: 12 }} />
										</div>
									</Dropdown>
									<div className={styles.productViewButton} style={{ width: 32, height: 32 }}>
										{
											isDownloadingCustomers ?
												<Spin /> :
												<img
													src='/img/download-green.svg'
													style={{
														cursor: !permissions.exportDistributors ? 'not-allowed' : 'pointer',
														filter: !permissions.exportDistributors ? 'invert(89%) sepia(0%) saturate(0%) hue-rotate(184deg) brightness(88%) contrast(104%)' : undefined
													}}
													onClick={() => downloadTopCustomersCsv()}
												/>
										}
									</div>
								</div>
							</div>
						</div>
						<div style={{ marginBottom: 24 }}>
							{renderDateRange(customerDateRange)}
						</div>
						<Table
							style={{ marginTop: '24px' }}
							loading={isTopCustomerLoading}
							columns={customerColumns}
							dataSource={topECommerceCustomers}
							pagination={false}
							rowKey='id'
							empty={<CustomEmptySecondary />}
						/>
					</div>
				</div>
			</div>)
	}
	return (
		<div className={styles.container}>
			{renderDashboardData()}
		</div>
	)
}

export default ECommerceDashboard
